.PieChartContainer
{
    width: 37vw;
    height: 18vw;
    /* background-color: red; */
    top: 50%;
    margin-left: 4vw;
}
.Chars
{
    position: absolute;
    top: 9vw;
    left: 7vw;
    margin-left: 0vw;
    background-color: rgb(44, 44, 44);
    border-radius: 3vw;
    width: 80%;
    height: 40vw;
}