.BookingSelContainer {
  width: 100vw;
  height: 46.7vw;
  position: relative;
  margin-top: 0rem;
  /* background-color: blue; */
  padding-top: 0.1vw;
  overflow-x: hidden;
}

.BookingImagesContainer {
  position: relative;
  margin-left: 9vw;
  margin-top: 2vw;
  height: 39.5vw;
  width: 38vw;
  /* background-color: red; */
}

#BookingControlR {
  position: absolute;
  top: 0rem;
  left: 0rem;
  width: 28vw;
  height: 25vw;
  border-radius: 1.2vw;
  box-shadow: 0.3vw 0.3vw 0.15vw 0px rgba(0, 0, 0, 0.70);
  z-index: 0;
  object-fit: cover;
  transition: 0.3s all ease-in-out;
}

#BookingControlR:hover {
  transform: scale(1.1);
}

#BookingMic {
  position: absolute;
  top: 18vw;
  right: 2vw;
  width: 23vw;
  height: 21vw;
  border-radius: 2.2vw;
  box-shadow: 0.3vw 0.3vw 0.15vw 0px rgba(0, 0, 0, 0.70);
  object-fit: cover;
  object-position: 0% 70%;
  z-index: 0;
  transition: 0.3s all ease-in-out;
}

#BookingMic:hover {
  transform: scale(1.1);
}

.Grids {
  position: absolute;
  top: 25vw;
  left: 0;
}

#GridDots {
  position: absolute;
  width: 5vw;
  height: 20vw;
  top: 0;
  object-fit: cover;
  left: 0;
}

.StarContainer {
  position: absolute;
  top: 1vw;
  left: 99%;
  transform: translateX(-99%);
}

#Star {
  width: 5vw;
  height: 5vw;
}

.BookingTextContainer {
  position: absolute;
  width: 37vw;
  height: 5vw;
  right: 11vw;
  top: 11vw;
}

#unlock-text,
#Reserve-Text {
  font-family: Morn;
  color: #FFF;
  font-size: 2.1vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

#Reserve-Text {
  color: #F5BC25;
}

.BookingTextContainer p {
  color: #BABABA;
  font-family: Mont;
  font-size: 1.5vw;
  font-style: normal;
  font-weight: 400;
  line-height: 161.4%;
  /* 2.72363rem */
  margin-top: 1vw;
}

.BookingButtonContainer {
  margin-top: 2vw;
  width: 34vw;
}

.JamRoomButton {
  width: 15vw;
  height: 4vw;
  border-radius: 1.6vw;
  border-width: 0.2vw;
  background: rgba(245, 188, 37, 0.81);
  cursor: pointer;
  color: #000;
  font-family: LeagueGothic;
  font-size: 2vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.128vw;
  transition: 0.2s all ease-in-out;
}

#Guitar {
  position: relative;
  width: 2.2vw;
  height: 2.2vw;
  top: 0.2vw;
  left: 0.7vw;
}

.StudioButton {
  margin-left: 2vw;
  width: 15vw;
  height: 4vw;
  border-radius: 1.6vw;
  background: rgba(245, 188, 37, 0.81);
  cursor: pointer;
  color: #000;
  border-width: 0.2vw;
  font-family: LeagueGothic;
  font-size: 2vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.128vw;
  transition: 0.2s all ease-in-out;
}

#Microphone {
  position: relative;
  width: 2.2vw;
  height: 2.2vw;
  top: 0.2vw;
  left: 0.7vw;
}

.StudioButton:hover {
  background-color: #F5BC25;
  transform: scale(1.2);
}

.JamRoomButton:hover {
  background-color: #F5BC25;
  transform: scale(1.2);
}

.LogoContainer {
  position: absolute;
  top: 41vw;
  left: 98%;
  transform: translateX(-98%);
}

#StudioLogo {
  width: 5vw;
  height: 5vw;
}

@media screen and (min-width:0px) and (max-width:767px) {
  .BookingSelContainer {
    width: 100vw;
    height: 60vh;
    position: relative;
    margin-top: 1vw;
    padding-top: 0.1vw;

  }

  .BookingImagesContainer {
    /* background-color: red; */
    margin-top: 3vh;
    width: 80vw;
    height: 20vh;
  }

  #BookingControlR {
    position: absolute;
    top: 0rem;
    left: 0rem;
    width: 39vw;
    height: 20vh;
    border-radius: 1.2vw;
    box-shadow: 0.3vw 0.3vw 0.15vw 0px rgba(0, 0, 0, 0.70);
    z-index: 0;
    object-fit: cover;
    transition: 0.3s all ease-in-out;
  }

  #BookingControlR:hover {
    transform: scale(1.1);
  }

  #BookingMic {
    position: absolute;
    top: 0;
    right: 0%;
    width: 39vw;
    height: 20vh;
    border-radius: 2.2vw;
    box-shadow: 0.3vw 0.3vw 0.15vw 0px rgba(0, 0, 0, 0.70);
    object-fit: cover;
    object-position: 0% 70%;
    z-index: 0;
    transition: 0.3s all ease-in-out;
  }

  #BookingMic:hover {
    transform: scale(1.1);
  }

  .Grids {
    position: absolute;
    top: 40%;
    left: 0;
  }

  #GridDots {
    position: absolute;
    width: 7vw;
    height: 25vw;
    top: 0;
    object-fit: cover;
    left: 0;
  }

  .StarContainer {
    position: absolute;
    top: 1vw;
    left: 99%;
    transform: translateX(-99%);
  }

  #Star {
    width: 7vw;
    height: 7vw;
  }

  .BookingTextContainer {
    position: relative;
    width: 72vw;
    height: 9vw;
    /* right: 11vw; */
    left: 50%;
    top: 15%;
    /* background-color: red; */
    transform: translate(-50%, -50%);
  }

  .BookingTextContainer h1,
  a {
    font-family: Morn;
    color: #FFF;
    font-size: 3.5vw;
  }

  #unlock-text {
    font-size: 4vw;
  }

  #Reserve-Text {
    font-size: 4vw;
  }

  .BookingTextContainer a {
    color: #F5BC25;
  }

  .BookingTextContainer p {
    color: #BABABA;
    font-family: Mont;
    font-size: 3vw;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    /* 2.72363rem */
    margin-top: 3vw;
  }

  .BookingButtonContainer {
    margin-top: 5vw;
    width: 85vw;
  }

  .JamRoomButton {
    width: 36vw;
    height: 8vw;
    border-radius: 2.5vw;
    border-width: 0.2vw;
    background: rgba(245, 188, 37, 0.81);
    cursor: pointer;
    color: #000;
    font-family: LeagueGothic;
    font-size: 5vw;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.128vw;
    transition: 0.2s all ease-in-out;
  }

  #Guitar {
    position: relative;
    width: 4.8vw;
    height: 4.8vw;
    top: 0.2vw;
    left: 0.7vw;
  }

  .StudioButton {
    margin-left: 3vw;
    width: 36vw;
    height: 8vw;
    border-radius: 2.5vw;
    border-width: 0.2vw;
    background: rgba(245, 188, 37, 0.81);
    cursor: pointer;
    color: #000;
    font-family: LeagueGothic;
    font-size: 5vw;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.128vw;
    transition: 0.2s all ease-in-out;
  }

  #Microphone {
    position: relative;
    width: 4.8vw;
    height: 4.8vw;
    top: 0.2vw;
    left: 0.7vw;
  }

  .StudioButton:hover {
    background-color: #F5BC25;
    transform: scale(1.2);
  }

  .JamRoomButton:hover {
    background-color: #F5BC25;
    transform: scale(1.2);
  }

  .LogoContainer {
    position: absolute;
    top: 91%;
    left: 98%;
    transform: translateX(-98%);
  }

  #StudioLogo {
    width: 5vw;
    height: 5vw;
  }
}