

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.homecontainer {
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.background-image {
  position: relative;
  top: 0;
  left: 0;
  overflow: hidden;
  height: 100vh;
  width: 100vw;
  z-index: -1;
  transform: translateX(100%);
  animation: slideInBackground 1.2s forwards;
  background-color: #2F2F2F;
  will-change: opacity, transform;
}

.background-image img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: 0% 40%;
}

@keyframes slideInBackground {
  to {
    transform: translateX(0);
  }
}

.rectangle1 {
  position: absolute;
  width: 10.5vw;
  height: 3.7vw;
  top: 9.3vw;
  left: 0;
  background-color: #F5BC25;
  transform: translateX(-100%);
  animation: slideInLeftHome 1.4s forwards;
  will-change: opacity, transform;
}

.rectangle {
  position: relative;
  width: 80%;
  height: 5.5vw;
  top: 50%;
  left: 0;
  background-color: #2F2F2F;
  transform: translateX(-100%);
  animation: slideInLeftHome 1.4s forwards;
  z-index: -1;
  will-change: opacity, transform;
}

@keyframes slideInLeftHome {
  to {
    transform: translateX(0);
  }
}

.hero-text-container {
  position: absolute;
  width: 60vw;
  height: 10vw;
  top: 41%;
}

.rectangle2 {
  position: absolute;
  width: 3.4vw;
  height: 14vw;
  top: 38%;
  right: 0;
  background-color: #F5BC25;
  transform: translateX(100%);
  animation: slideInRightHome 1.4s forwards;
  will-change: opacity, transform;
}

@keyframes slideInRightHome {
  to {
    transform: translateX(0);
  }
}

.herotext {
  width: 35vw;
  height: fit-content;
  top: -0.5vw;
  color: #fff;
  left: 9.2vw;
  font: 1200 12vw/5.3vw Mont; 
  font-weight: 2000;
  font-size: 2vw;
  position: absolute;
  opacity: 0;
  transform: scale(0.5) translateY(100%);
  animation: fadeInAndScale 1.4s ease-in-out forwards, slideInFromBottom 1.4s ease-in-out 0s forwards;
  will-change: opacity, transform;
}

#thoughts-text
{
  font: 1200 12vw/5.3vw Mont;
}
#thoughts-span
{
  position: absolute;
  width: 50vw;
  left: 0;
  height: fit-content;
  margin-top: 5.5vw;
  font: 1200 12vw/5.3vw Mont;
}

@keyframes fadeInAndScale {
  to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes slideInFromBottom {
  to {
    transform: translateY(0);
  }
}

.herotext span {
  color: #F5BC25;
}

.sjecstudio {
  position: absolute;
  right: 1vw;
  top: 95%;
}

.sjecstudio h1 {
  color: #D9D9D9;
  font-family: mont;
  font-size: 1vw;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0.2vw;
  transform: translateX(100%);
  animation: slideInRightHome 1.2s forwards;
}

.buttoncontainer {
  top: 82%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 43vw;
  border-radius: 2.2vw;
  transform: scale(0.3) translateY(100%);
  animation: fadeInAndScale 1.2s ease-in-out forwards, slideInFromBottom 1.2s ease-in-out 1.2s forwards;
  will-change: opacity, transform;
}

#book-now-button {
  padding: 0.6vw 2vw;
  background: #F5BC25;
  font-size: 2vw;
  font-family: staat;
  font-weight: 500;
  color: #181818;
  border: 0.2vw solid #F5BC25;
  border-radius: 2.2vw;
  letter-spacing: 0.2vw;
  box-shadow: 0 0 0 #fec1958c;
  cursor: pointer;
  transition: all 0.1s ease-in-out;
}

#book-now-button:hover {
  background: transparent;
  color: #F5BC25;
  box-shadow: 0 0 2vw #fec1958c;
}

@media screen and (min-width: 0px) and (max-width: 768px) {
  .homecontainer {
    width: 100vw;
    overflow-x: hidden;
  }

  .background-image {
    transform: translateX(0);
  }

  .background-image img {
    position: absolute;
    object-position: 70% 100%;
    transform: translateX(0%);
    animation: none;
  }

  .rectangle1 {
    width: 20vw;
    height: 8vw;
    top: 15vh;
    transform: translateX(0%);
    animation: none;
  }

  .hero-text-container {
    width: 95vw;
    height: 21vw;
  }
  #thoughts-span
  {
    position: absolute;
    left: 0;
    width: 95vw;
    top: 5vw;
    left: 8.8vw;
  }

  .rectangle {
    position: absolute;
    top: 45%;
    width: 94%;
    height: 12vw;
    transform: translateX(0%);
    animation: none;
  }

  .rectangle2 {
    height: 30vw;
    top: 40vh;
    transform: translateX(0%);
    animation: none;
  }

  .herotext {
    width: 85vw;
    height: fit-content;
    left: 0vw;
    padding-left: 8vw;
    font: bold 4.3vw/10vw mont;
    line-height: 10vw;
    transform: scale(1) translateY(0%);
    animation: fadeInAndScale 0.2s ease-in-out forwards, slideInFromBottom 0.2s ease-in-out 0.2s forwards;
  }

  .sjecstudio {
    position: absolute;
    right: 1.5vw;
    top: 87vh;
  }

  .sjecstudio h1 {
    color: #D9D9D9;
    font-family: mont;
    font-size: 2.3vw;
    font-style: normal;
    font-weight: 700;
    letter-spacing: 0.3vw;
    transform: translateX(0%);
    animation: none;
  }

  .buttoncontainer {
    left: 33vw;
    border-radius: 2.2vw;
    transform: none;
    animation: none;
  }

  #book-now-button {
    padding: 0.9vw 4.5vw;
    font-size: 6vw;
    border-radius: 5vw;
    letter-spacing: 0.2vw;

  }


}
