.naviagtion {
    top: 0vh;
    color: #FFF;
    overflow: hidden;
    position: fixed;
    width: 100vw;
    height: 6vw;
    z-index: 1000;
    background-color: transparent;
    transition: top 0.3s ease-in-out, background-color 0.3s ease-in-out;
}

.naviagtion.scrolling {
    top: -5vw;
    background-color: black;
}

.naviagtion:hover {
    top: 0;
    backdrop-filter: blur(5px);
    background-color: rgba(0, 0, 0, 0.8);
}

.logoContainers {
    margin-top: 0rem;
    margin-left: 3.5vw;
    z-index: 1;
    position: absolute;
    width: 12vw;
    height: 6vw;
}

.navbar-logo {
    width: 2.8vw;
    height: 2.8vw;
    align-items: center;
    top: 38%;
    left: 25%;
    transform: translateX(-50%);
    transform: translateY(-50%);
    position: absolute;
}

.melodium-logo-container {
    height: 3.8vw;
    width: 5.5vw;
    left: 45%;
    top: 20%;
    position: absolute;
}

.melodium-text {
    text-align: center;
    font-weight: 700;
    line-height: normal;
    color: #F5CB05;
    font-family: mont;
    font-size: 0.6vw;
    font-style: normal;
    font-weight: 700;
    letter-spacing: 0.1vw;
    position: absolute;
    top: 90%;
    left: 50%;
    transform: translate(-50%, -50%);
}

#sjec-logo {
    width: 3.6vw;
    height: 3.4vw;
    align-items: center;
    top: 50%;
    transform: translateY(-50%);
    position: absolute;
    transition: 0.2s ease-in-out all;
}

#sjec-logo:hover {
    scale: 1.1;
}

.line {
    background-color: #FFC107;
    height: 3.4vw;
    width: 0.1vw;
    position: absolute;
    top: 50%;
    left: 38%;
    transform: translateY(-50%);
}

.menu-link {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 3vw;
}

.menu-link ul {
    position: relative;
    list-style: none;
    display: flex;
    align-items: center;
    height: 3vw;
}

.menu-link li {
    height: 2vw;
    margin: 0;
    padding: 0;
}

.menu-link button {
    text-decoration: none;
    color: #FFF;
    cursor: pointer;
    font-family: roboto;
    font-size: 0.9vw;
    font-weight: 900;
    letter-spacing: 0.25vw;
    transition: color 0.3s;
    margin-top: -2vw;
    margin: 0px 0.8vw;
    padding: 0.3vw;
    background-color: transparent;
    border: none;
}

.menu-link button:hover {
    color: #FFC107;
}

.socialmediaDesktop {
    position: absolute;
    list-style: none;
    display: flex;
    top: 50%;
    left: 93%;
    transform: translate(-50%, -50%);
}

.socialmediaDesktop li svg {
    margin-right: 0.8vw;
    cursor: pointer;
    transition: 0.3s all ease-in-out;
    height: 1.7vw;
    width: 1.7vw;
}

.insta path:hover {
    fill: #E4405F;
}

.facebook path:hover {
    fill: #1877f2;
}

.sound path:hover {
    fill: #ff7700;
}

#navbar-arrow {
    position: absolute;
    top: 5vw;
    left: 50vw;
}

@media screen and (min-width:768px) {
    .mobile-menu-icon {
        display: none;
    }
}

@media screen and (min-width:1024px) and (max-width:1279px) {
    .menu-link {
        height: 2vw;
    }

    .menu-link li {
        height: 3vw;
    }
}

@media screen and (min-width:767px) and (max-width:1023px) {
    .menu-link ul {
        height: 4vw;
    }

    .menu-link li {
        height: 4.5vw;
    }
}

@media screen and (max-width:768px) {
    .naviagtion {
        height: 15vw;
        background-color: rgba(0, 0, 0, 0);
        overflow: visible;
    }

    .mobile-menu-icon {
        transition: transform 0.3s ease; 
      }
      
      .mobile-menu-icon.clicked {
        transform: rotate(90deg); 
      }
      .logoContainers
      {
        margin-top:  4vw;
        left: 70%;
        z-index: 1;
        position: absolute;
        width: 12vw;
        height: 6vw;
    }

    .melodium-logo-container {
        position: absolute;
        height: 9vw;
        width: 9vw;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-left: 9vw;
        margin-top: -2vw;
    }

    .navbar-logo {
        width: 8vw;
        height: 8vw;
        left: 5%;
    }

    .melodium-text {
        font-size: 1.25vw;
        letter-spacing: 0.2vw;
        top: 100%;
    }

    #sjec-logo {
        width: 10vw;
        height: 9vw;
    }

    .line {
        height: 9vw;
        width: 0.5vw;
        margin-left: 3vw;
        left: 75%;
    }

    .menu-link {
        display: none;
    }

    .mobile-menu-icon {
        position: absolute;
        top: 3vw;
        left: 2%;
        font-size: 8vw;
        color: white;
        height: 7vw;
        width: 7vw;
        cursor: pointer;
    }

    .socialmediaDesktop {
        display: none;
    }

    .socialmediaDesktopMobile {
        position: fixed;
        list-style: none;
        display: flex;
        top: 80vh;
        left: -24.5%;
        transform: translate(-50%, -50%);
        z-index: 4;
        transform: translateX(100%);
        transition: 0.3s ease-in-out all;
    }

    .socialmediaDesktopMobile.active {
        transform: translateX(0%);
    }

    .socialmediaDesktopMobile li svg {
        margin-right: 8vw;
        cursor: pointer;
        transition: 0.3s all ease-in-out;
        height: 6vw;
        width: 6vw;
    }

    .nav-menu-mobile {
        position: fixed;
        height: 100vh;
        background-color: rgba(0, 0, 0, 1);
        backdrop-filter: blur(5px);
        width: 30vw;
        left: -30vw;
        top: 10.5vw;
        z-index: -1;
        display: flex;
        justify-content: center;
        transform: translateX(100%);
        transition: 0.3s ease-in-out all;
    }

    .nav-menu-mobile.active {
        transform: translateX(0%);
    }

    .nav-menu-mobile ul {
        list-style: none;
    }

    .nav-menu-mobile li {
        font-size: 4vw;
        margin-top: 6vh;
    }

    .nav-menu-mobile li:first-child {
        font-size: 4vw;
        margin-top: 8vh;
    }

    .nav-menu-mobile button {
        font-size: 4.5vw;
        margin-top: 2vw;
        background-color: transparent;
        border: none;
        color: white;
    }

    .naviagtion.scrolling {
        top: 0;
        background-color: rgba(0, 0, 0, 0.6);
        backdrop-filter: blur(5px);
    }

    #navbar-arrow {
        display: none;
    }
}