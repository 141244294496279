.BookingOneContainer {
    height: 100vh;
    width: 100vw;
    position: relative;
    overflow: hidden;
}

.MMYY {
    text-align: center;
    color: white;
    font-family: Amar;
    font-size: 3rem;
    font-style: normal;
    font-weight: 400;
    margin-top: 2rem;
}

.FDates {
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
}

.DatesS {
    display: flex;
    width: 80rem;
    margin-top: 1rem;
    padding-left: 5.25rem;
    padding-right: 5.25rem;
    height: 6.5rem;
    border: 3px solid #FFF;
    align-items: center;
    justify-content: center;

}

.DayDate {
    text-align: center;
    display: inline-block;
    width: 12rem;
    height: 6.2rem;
    margin-top: 0rem;
    margin-left: 10px;
    position: relative;
    color: #A4A4A4;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
}

.DayDate .Day {
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-family: Morn;
    color: #A4A4A4;
    font-size: 1.7rem;
    font-style: normal;
    font-weight: 900;
}

.DayDate .Date {
    position: absolute;
    top: 70%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #F5BC25;
    font-family: Morn;
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 900;
    transition: all 0.3s ease-in-out;
}

.DayDate:hover {
    background-color: #F5BC25;
    color: black;
}

.DayDate:hover .Day {
    color: black;
}

.DayDate:hover .Date {
    color: black;
}

.SDayDate {
    text-align: center;
    display: inline-block;
    background-color: #F5BC25;
    width: 12rem;
    height: 6.2rem;
    margin-top: 0rem;
    margin-left: 10px;
    position: relative;
}

.SDayDate .Day {
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-family: Morn;
    color: black;
    font-size: 1.7rem;
    font-style: normal;
    font-weight: 900;
}

.SDayDate .Date {
    position: absolute;
    top: 70%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: black;
    font-family: Morn;
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 900;
}

.Header {
    position: relative;
    top: 57%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.SelDate {
    display: flex;
    align-items: center;
    color: white;
    font-family: Morn;
    font-size: 1.4rem;
}

.Calendar {
    width: 2rem;
    height: 3rem;
    margin-right: 10px;
}

.DispTimeSlots {
    position: absolute;
    top: 62%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin-top: 5rem;
    display: flex;
    flex-wrap: wrap;
    width: 75rem;
    align-items: center;
    justify-content: center;
    color: white;
    font-family: Morn;
    font-size: 3rem;
}

.DispTimeSlots>* {
    margin: 1rem 1rem;
}

.ProButton {
    position: absolute;
    top: 88%;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    width: 10rem;
    height: 4rem;
    border: 3px solid #F5BC25;
    border-radius: 2.5rem;
    font-family: Morn;
    color: white;
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    background-color: transparent;
    transition: 0.3s all ease-in-out;
    cursor: pointer;
}

.ProButton:hover {
    background-color: #F5BC25;
    color: black;
    font-weight: 600;
}

.TimeS {
    position: relative;
    display: flex;
    align-items: center;
    /* Vertically center content */
    justify-content: center;
    width: 18vw;
    height: 4vw;
    border-radius: 1.5vw;
    background: #D9D9D9;
    color: black;
    font-family: morn;
    font-size: 1.3vw;
    font-style: normal;
    font-weight: 700;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
}

.TimeS:hover {
    background-color: #E54545;
}

.TimeS.selected {
    background-color: #E54545;
}

@media screen and (min-width: 0px) and (max-width:768px) {
    .BookingOneContainer {
        overflow: hidden;
        height: fit-content;
        text-align: center;

        margin-top: 0;
    }

    .MMYY {
        font-size: 6vw;
        margin-top: 3vw;
    }

    .DatesS {
        width: 98vw;
        padding-left: 0.1vw;
        padding-right: 0.1vw;
        height: 9vh;
        border-width: 0.2vw;
        margin-top: 4vw;
        /* background-color: red; */
    }

    .DayDate {
        width: 20vw;
        height: 9vh;
        margin-top: 0rem;
        margin-left: 0.5vw;
        /* background-color: black; */
    }

    .DayDate .Date {
        position: relative;
        font-size: 2.8vw;
    }

    .DayDate .Day {
        /* position: relative; */
        font-size: 2.5vw;
    }

    .SDayDate {
        width: 19.2vw;
        height: 9vh;
        margin-top: 0rem;
        margin-left: 0.5vw;
    }

    .SDayDate .Day {
        font-size: 2.9vw;
    }

    .SDayDate .Date {
        position: relative;
        font-size: 3vw;
    }

    .TimeS {
        width: 40vw;
        height: 10vh;
        border-radius: 4vw;
        background: #D9D9D9;
        color: black;
        font-family: 'Inter';
        font-size: 4.5vw;
        font-style: normal;
        font-weight: 700;
        transition: all 0.2s ease-in-out;
        cursor: pointer;
        /* background-color: red; */
    }

    .DispTimeSlots {
        position: relative;
        margin-top: 0vw;
        width: 98vw;
        left: 50%;
        height: fit-content;
        transform: translateX(-50%);
        row-gap: 0;
        /* background-color: red; */
    }

    :root {
        --vw: 10vw;
        /* Set a CSS variable with the desired viewport unit value */
    }

    .Calendar {
        width: var(--vw);
        /* Set the width based on the CSS variable */
        height: auto;
        /* Allow the height to adjust proportionally */
    }


    .SelDate {
        font-size: 4.5vw;
    }

    .Header {
        position: relative;
        margin-top: 4vw;
        /* Adjust this value as needed */
        width: fit-content;
        left: 50%;
        transform: translateX(-50%);
    }

    .ProButton {
        position: relative;
        left: 15%;
        transform: translateX(-50%);
        margin-top: 1vw;
        width: 30vw;
        height: 10vw;
        border: 0.2vw solid #F5BC25;
        border-radius: 4vw;
        font-size: 6vw;
        border-width: 0.2vw;
    }
}

@media screen and (min-width: 768px) and (max-width:1023px) {
    .BookingOneContainer {
        overflow-y: scroll;
        height: fit-content;
    }

    .MMYY {
        font-size: 3.5vw;
        margin-top: 1vw;
    }

    .DatesS {
        width: 85vw;
        padding-left: 0.1vw;
        padding-right: 0.1vw;
        height: 8vw;
        border-width: 0.2vw;
        margin-top: 2vw;
        /* background-color: red; */
    }

    .DayDate {
        width: 13vw;
        height: 7.6vw;
        margin-top: 0rem;
        margin-left: 0.5vw;
    }

    .DayDate .Date {
        font-size: 1.8vw;
    }

    .DayDate .Day {
        font-size: 1.9vw;
    }

    .SDayDate {
        width: 13vw;
        height: 7.6vw;
        margin-top: 0rem;
        margin-left: 0.5vw;
    }

    .SDayDate .Day {
        font-size: 1.8vw;
    }

    .SDayDate .Date {
        font-size: 1.9vw;
    }

    .TimeS {
        width: 25vw;
        height: 5vw;
        border-radius: 1.5vw;
        font-size: 2.2vw;
        font-style: normal;
        font-weight: 700;
        transition: all 0.2s ease-in-out;
        cursor: pointer;
    }

    .DispTimeSlots {
        position: relative;
        margin-top: 2vw;
        width: 80vw;
        left: 50%;
        height: fit-content;
        transform: translateX(-50%);
        row-gap: 0;
    }

    :root {
        --vw: 10vw;
        /* Set a CSS variable with the desired viewport unit value */
    }

    .Calendar {
        width: var(--vw);
        /* Set the width based on the CSS variable */
        height: auto;
        /* Allow the height to adjust proportionally */
    }


    .SelDate {
        font-size: 2vw;
    }

    .Header {
        position: relative;
        margin-top: 4vw;
        /* Adjust this value as needed */
        width: fit-content;
        left: 50%;
        transform: translateX(-50%);
    }

    .ProButton {
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        margin-top: 2vw;
        width: 15vw;
        height: 5vw;
        border: 0.2vw solid #F5BC25;
        border-radius: 2.5vw;
        font-size: 3vw;
        border-width: 0.2vw;
    }
}

@media screen and (min-width: 1024px) {
    .BookingOneContainer {
        overflow-y: scroll;
        height: 100vh;
    }

    .MMYY {
        font-size: 3.5vw;
        margin-top: 1vw;
    }

    .DatesS {
        width: 85vw;
        padding-left: 0.1vw;
        padding-right: 0.1vw;
        height: 8vw;
        border-width: 0.2vw;
        /* background-color: red; */
    }

    .DayDate {
        width: 13vw;
        height: 7.6vw;
        margin-top: 0rem;
        margin-left: 0.5vw;
    }

    .DayDate .Date {
        font-size: 1.8vw;
    }

    .DayDate .Day {
        font-size: 1.9vw;
    }

    .SDayDate {
        width: 13vw;
        height: 7.6vw;
        margin-top: 0rem;
        margin-left: 0.5vw;
    }

    .SDayDate .Day {
        font-size: 1.8vw;
    }

    .SDayDate .Date {
        font-size: 1.9vw;
    }

    .TimeS {
        width: 18vw;
        height: 4vw;
        border-radius: 1.5vw;
        font-size: 1.7vw;
    }

    .DispTimeSlots {
        position: relative;
        margin-top: 6.5vw;
        width: 85vw;
        font-size: 3vw;
        /* background-color: red; */
    }

    :root {
        --vw: 10vw;
        /* Set a CSS variable with the desired viewport unit value */
    }

    .Calendar {
        width: var(--vw);
        /* Set the width based on the CSS variable */
        height: auto;
        /* Allow the height to adjust proportionally */
    }


    .SelDate {
        /* margin-top: 1vw; */
        font-size: 1.7vw;
    }

    .Header {
        position: relative;
        margin-top: 1vw;
        /* Adjust this value as needed */
        width: fit-content;
        left: 50%;
        transform: translateX(-50%);
    }

    .ProButton {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        margin-top: 0;
        width: 15vw;
        height: 5vw;
        border: 0.2vw solid #F5BC25;
        border-radius: 2.5vw;
        font-size: 3vw;
        border-width: 0.2vw;
    }
}

/* @media screen and (min-width: 768px) and (max-width: 1023px){
    body
    {
        overflow-y: scroll;
    }
    .MMYY
    {
        font-size: 2rem;
        margin-top: 0.5rem;
    }
    .DatesS
    {
        width: 35rem;
        padding-left: 4rem;
        padding-right: 4rem;
        height: 5rem;
    }
    .DayDate
    {
        width: 9rem;
        height: 5rem;
        margin-top: 0rem;
        margin-left: 12px;
        position: relative;
        color: #A4A4A4;
        transition: all 0.3s ease-in-out;
        cursor: pointer;
    }
    .DayDate .Date
    {
        font-size: 0.8rem;
    }
    .DayDate .Day
    {
        font-size: 1.1rem;
    }
    .SDayDate
    {
        width: 9rem;
        height: 5rem;
        margin-top: 0rem;
        margin-left: 12px;
    }
    .SDayDate .Day
    {
        font-size: 1rem;
    }
    .SDayDate .Date
    {
        font-size: 0.96rem;
    }
    .DispTimeSlots
    {
        margin-top: 3rem;
        width:  38rem;
        font-size: 3rem;
        row-gap: 0;
    }
    .SelDate {
        font-size: 1rem;
      }
      .Header 
    {
        top: 49%; 
    }
    .Calendar
    {
        scale: 0.7;
    }
    .ProButton 
    {
        top: 100%;
        width: 6rem;
        height: 2.5rem;
        border:2px solid #F5BC25;
        border-radius: 1rem;
        font-size: 1rem;
      }
  } */