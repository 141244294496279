body {
    overflow-x: hidden;
    scrollbar-width: none;
    background-color: #21201E;
    height: 100vh;
}

::-webkit-scrollbar {
    display: none;
}

.App {
    width: 100vw;
    height: 100vh;
    position: relative;
}

body {
    background-color: #21201E;
    overflow: scroll;
}

* {
    box-sizing: border-box;
}

@media screen and (max-width:767px) {    
}