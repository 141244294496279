.BookingThreeContainer {
    width: 100vw;
    height: 100vh;
    position: relative;
}

.DisplayContainer {
    position: absolute;
    width: 76rem;
    height: 34rem;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 1.875rem;
    background: #3A3A3A;
    color: #FFF;
}

.DisplayContainer h1 {
    position: absolute;
    font-family: Morn;
    font-size: 2rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: #F5BC25;
    top: 3rem;
    left: 8rem;
}

.DisplayContainer :nth-child(3) {
    top: 3rem;
    left: 40rem;
}

.DisplayContainer h1:nth-child(4) {
    top: 6rem;
    left: 8rem;
    color: #FFF;
    border-bottom: 3px solid white;
}

.DisplayContainer h1:nth-child(5) {
    color: #fff;
    top: 6rem;
    left: 40rem;
    border-bottom: 3px solid #fff;
}

.DisplayContainer h1:nth-child(6) {
    top: 11rem;
    left: 8rem;
}

.DisplayContainer :nth-child(7) {
    top: 11rem;
    left: 40rem;
}

.DisplayContainer :nth-child(8) {
    color: #FFF;
    top: 13.5rem;
    left: 8rem;
    border-bottom: 3px solid white;
}

.DisplayContainer :nth-child(9) {
    top: 13.5rem;
    left: 40rem;
    color: white;
    border-bottom: 3px solid white;
}

.DisplayContainer :nth-child(10) {
    top: 18rem;
    left: 8rem;
    color: #F5BC25;
}

.DisplayContainer :nth-child(11) {
    top: 1rem;
    left: 67rem;
    color: white;
    font-size: 1.5rem;
}

.DisplayContainer input,
select {
    position: absolute;
    width: 27rem;
    height: 3rem;
    border: 2px solid #FFF;
    background: rgba(25, 25, 25, 0.40);
    font-family: Morn;
    font-size: 1.7rem;
    top: 6.5rem;
    left: 8rem;
    padding: 0.2rem 0.5rem;
    color: #FFF;
    transition: 0.3s all ease-in-out;
}




.DisplayContainer select {
    top: 22rem;
    left: 8rem;
    width: 60rem;
    height: 3.5rem;
    border-radius: 1.875rem;
    appearance: none;
    /* Remove default styles */
    -webkit-appearance: none;
    cursor: pointer;

}

.DisplayContainer input:hover {
    background-color: #E54545;
}

.DisplayContainer select:hover {
    background-color: #E54545;
}

.DisplayContainer #Submit {
    position: absolute;
    top: 85%;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    width: 10rem;
    height: 4rem;
    border: 3px solid #F5BC25;
    border-radius: 2.5rem;
    font-family: morn;
    color: white;
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    background-color: transparent;
    transition: 0.3s all ease-in-out;
    cursor: pointer;
}

.DisplayContainer #Submit:hover {
    background-color: #F5BC25;
    color: black;
    font-weight: 600;
}

.TimeSlotContainer {
    position: absolute;
    display: flex;
    flex-wrap: wrap;
    width: 70rem;
    height: 8rem;
    top: 33.5rem;
    left: 22rem;
}

#ConfirmButton {
    position: absolute;
    font-family: Morn;
    font-size: 1.7rem;
    font-weight: 600;
    width: 13rem;
    height: 4rem;
    border-radius: 2.5rem;
    background: rgba(245, 188, 37, 0.81);
    left: 50%;
    transform: translateX(-50%);
    top: 42rem;
    transition: 0.2s all ease-in-out;
    cursor: pointer;
}

#ConfirmButton:hover {
    background-color: #F5BC25;
    cursor: pointer;
}

.melodium-logo-confirm {
    position: absolute;
    top: 0.6rem;
    left: 0.8rem;
    width: 4rem;
    height: 4rem;
}

@media screen and (max-width:768px) {
    .BookingThreeContainer {
        margin-top: 4vw;
        height: fit-content;
    }

    .DisplayContainer {
        width: 80vw;
        height: 82vh;
        top: 5%;
        left: 50%;
        transform: translateX(-50%);
        border-radius: 1.875vw;
        /* background-color: red; */
    }

    .DisplayContainer h1 {
        font-size: 4vw;
        top: 18%;
        left: 4%;
    }

    .DisplayContainer :nth-child(3) {
        top: 30%;
        left: 4%;
    }

    .DisplayContainer h1:nth-child(4) {
        top: 22%;
        left: 4%;
    }

    .DisplayContainer h1:nth-child(5) {
        top: 34%;
        left: 4%;
    }

    .DisplayContainer h1:nth-child(6) {
        top: 42%;
        left: 4%;
    }

    .DisplayContainer :nth-child(7) {
        top: 54%;
        left: 4%;
    }

    .DisplayContainer :nth-child(8) {
        top: 46%;
        left: 4%;
    }

    .DisplayContainer :nth-child(9) {
        top: 58%;
        left: 4%;
        color: white;
        border-bottom: 3px solid white;
    }

    .DisplayContainer :nth-child(10) {
        top: 66%;
        left: 4%;
        color: #F5BC25;
    }

    .DisplayContainer :nth-child(11) {
        top: 6%;
        left: 70%;
        font-size: 4vw;
        /* background-color: red; */
    }

    .slots-confirm-container {
        position: relative;
        width: 100%;
        top: 71%;
    }

    .TimeSlotContainer {
        position: relative;
        width: 75vw;
        height: fit-content;
        top: 0%;
        left: 4%;
        row-gap: 2vw;

    }

    .TimeSlotContainer>* {
        margin-left: 0.3vw;
    }

    .ConfirmButton {
        position: relative;
        font-size: 4vw;
        width: 20vw;
        height: 8vw;
        border-radius: 2.5vw;
        margin-top: 5vw;
        left: 50%;
        transform: translateX(-50%);
        background-color: transparent;
        color: white;
        border-width: 0.5vw;
        border-color: #F5BC25;
        cursor: pointer;
    }

    .ConfirmButton:hover {
        background-color: #F5BC25;
        cursor: pointer;
    }

    .melodium-logo-confirm {
        position: absolute;
        top: 2%;
        left: 4%;
        width: 15vw;
        height: 15vw;
    }
}

@media screen and (min-width:768px) and (max-width:1023px) {
    .DisplayContainer {
        width: 80vw;
        height: 60vw;
        top: 5%;
        left: 50%;
        transform: translateX(-50%);
        border-radius: 1.875vw;
    }

    .DisplayContainer h1 {
        font-size: 2.1vw;
        top: 10%;
        left: 13%;
    }

    .DisplayContainer :nth-child(3) {
        top: 10%;
        left: 57%;
    }

    .DisplayContainer h1:nth-child(4) {
        top: 18%;
        left: 13%;
    }

    .DisplayContainer h1:nth-child(5) {
        top: 18%;
        left: 57%;
    }

    .DisplayContainer h1:nth-child(6) {
        top: 33%;
        left: 13%;
    }

    .DisplayContainer :nth-child(7) {
        top: 33%;
        left: 57%;
    }

    .DisplayContainer :nth-child(8) {
        top: 41%;
        left: 13%;
    }

    .DisplayContainer :nth-child(9) {
        top: 41%;
        left: 57%;
        color: white;
        border-bottom: 3px solid white;
    }

    .DisplayContainer :nth-child(10) {
        top: 52%;
        left: 13%;
        color: #F5BC25;
    }

    .DisplayContainer :nth-child(11) {
        top: 3%;
        left: 89%;
        font-size: 1.5vw;
    }

    .TimeSlotContainer {
        width: 60vw;
        height: 10vw;
        top: 63%;
        left: 13%;
    }

    .TimeSlotContainer>* {
        margin-left: 0.3vw;
    }

    .ConfirmButton {
        position: absolute;
        font-size: 2vw;
        width: 9vw;
        height: 3vw;
        border-radius: 2.5vw;
        top: 90%;
        left: 50%;
        transform: translateX(-50%);
        background-color: transparent;
        color: white;
        border-width: 0.2vw;
        border-color: #F5BC25;
        cursor: pointer;
    }

    .melodium-logo-confirm {
        position: absolute;
        top: 1%;
        left: 1%;
        width: 5vw;
        height: 5vw;
    }
}

@media screen and (min-width:1024px) {

    /* .Slot
    {
        width: 15vw;
        font-size: 1.7vw;
        height: 4vw;
        border-radius: 1.3vw;
        margin: 0;

    } */
    .DisplayContainer {
        width: 80vw;
        height: 47vw;
        top: 5%;
        left: 50%;
        transform: translateX(-50%);
        border-radius: 1.875vw;
    }

    .DisplayContainer h1 {
        font-size: 2.1vw;
        top: 10%;
        left: 13%;
    }

    .DisplayContainer :nth-child(3) {
        top: 10%;
        left: 57%;
    }

    .DisplayContainer h1:nth-child(4) {
        top: 18%;
        left: 13%;
    }

    .DisplayContainer h1:nth-child(5) {
        top: 18%;
        left: 57%;
    }

    .DisplayContainer h1:nth-child(6) {
        top: 33%;
        left: 13%;
    }

    .DisplayContainer :nth-child(7) {
        top: 33%;
        left: 57%;
    }

    .DisplayContainer :nth-child(8) {
        top: 41%;
        left: 13%;
    }

    .DisplayContainer :nth-child(9) {
        top: 41%;
        left: 57%;
        color: white;
        border-bottom: 3px solid white;
    }

    .DisplayContainer :nth-child(10) {
        top: 52%;
        left: 13%;
        color: #F5BC25;
    }

    .DisplayContainer :nth-child(11) {
        top: 3%;
        left: 89%;
        font-size: 1.5vw;
    }

    .slots-confirm-container {
        position: relative;
        width: 100%;
        top: 59%;
    }

    .TimeSlotContainer {
        position: relative;
        width: 62vw;
        height: fit-content;
        top: 0%;
        left: 13%;
        margin: 0;
        padding: 0;
        column-gap: 2vw;
    }

    .ConfirmButton {
        position: relative;
        font-size: 2.5vw;
        width: 11vw;
        height: 4vw;
        border-radius: 2.5vw;
        margin-top: 1vw;
        left: 50%;
        transform: translateX(-50%);
        background-color: transparent;
        color: white;
        border-width: 0.2vw;
        border-color: #F5BC25;
        transition: 0.3s ease-in-out all;
        cursor: pointer;
    }

    .ConfirmButton:hover {
        background-color: #F5BC25;
        color: black;
    }

    .melodium-logo-confirm {
        position: absolute;
        top: 1%;
        left: 1%;
        width: 5vw;
        height: 5vw;
    }
}