.pagesixcontainer {
  position: relative;
  width: 100vw;
  height: 80vw;
  margin: 0;
  padding: 0;
  margin-top: 5vw;
  overflow-x: hidden;
}

* {
  margin: 0;
  padding: 0;
}

.circleser {
  position: absolute;
  top: 0;
  right: 0;
  width: 7vw;
  height: 9vw;
}

.circleser svg {
  width: 7vw;
  height: 9vw;
}

.herotextser {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #FFF;
  text-align: center;
  font-family: ava;
  font-size: 0.9vw;
  font-style: normal;
  font-weight: 400;
  line-height: 191.4%;
  top: 4%;

}

.herotextser span {
  color: #F5BC25;
}

.subser {
  color: #A8A8A8;
  font-family: LeagueGothic;
  font-size: 1.6vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 34%;
  left: 4%;
  width: 1.5vw;
  height: 30vw;
}

.serimages {
  position: absolute;
  left: 52%;
  top: 53%;
  transform: translate(-50%, -50%);
  width: 80vw;
  height: 65vw;
}

.serimages-1 {
  width: 80vw;
  display: flex;
  flex-wrap: wrap;
  gap: 7.2vw;
  row-gap: 3vw;
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20vw);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.serimages-1.animate {
  animation: fadeInUp 1s ease-in-out forwards;
}

.serimages-2.animate {
  animation: fadeInUp 1s ease-in-out forwards;
}

.serimages-3.animate {
  animation: fadeInUp 1s ease-in-out forwards;
}

.serimages-2 {
  position: relative;
  margin-top: 7vw;
  width: 80vw;
  display: flex;
  flex-wrap: wrap;
  gap: 7.2vw;
  row-gap: 3vw;
}

.serimages-3 {
  position: relative;
  margin-top: 7vw;
  width: 80vw;
  display: flex;
  flex-wrap: wrap;
  gap: 7.2vw;
  row-gap: 3vw;
}

.serimages-1,
.serimages-2,
.serimages-3 {
  display: flex;
  align-items: center;
}

.servicecircle {
  position: absolute;
  top: 92%;
  left: 3%;
}

.servicecircle svg {
  width: 3vw;
  height: 3vw;
}

.service1rect {
  position: absolute;
  width: 0.2vw;
  height: 11.25vw;
  background-color: #FFF;
  top: 81%;
  left: 95.6%;
}

.service2rect {
  position: absolute;
  width: 11.25vw;
  height: 0.2vw;
  background-color: #FFF;
  top: 94.8%;
  left: 84.5%;
}

@media screen and (min-width:0px) and (max-width:767px) {
  .pagesixcontainer {
    height: 100vh;
    overflow: hidden;
  }

  .serimages {
    top: 52%;
    left: 50%;
    width: 71vw;
    height: 84%;
    row-gap: 2vw;
  }

 

  .subser {
    font-size: 4vw;
    top: 25%;
    left: 4%;
    width: 1.5vw;
    height: 30vw;
  }

  .servicecircle {
    top: 85%;
    left: 0;
    width: 5vw;
    height: 5vw;
  }

  .servicecircle svg {
    width: 5vw;
    height: 5vw;
  }

  .herotextser {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 2vh;
    font-size: 1.6vw;
    line-height: 5vw;
    width: 78vw;
  }

  .bottom-service-rectangle-container {
    position: absolute;
    width: 90vw;
    height: 20vw;
    top: 90%;
    left: 5%;
    transform: translateY(-50%);
  }

  .service1rect {
    top: 0%;
    left: 100%;
    height: 20vw;
    width: 0.4vw;
  }

  .service2rect {
    top: 99%;
    left: 78%;
    width: 20vw;
    height: 0.4vw;
  }
}