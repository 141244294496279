.AnalyticsContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100vw;
    height: 100vh;
  }
  .text-wrapper-analytics {
    position: absolute;
    color: white;
    font-family: 'Morn';
    font-size: 3vw;   
    margin-top: 1.5vw;
    left: 20vw;
  }
  .analytics-line
{
    position: absolute;
    left: 20vw;
    top: 6.5vw;
    background-color: #F5BC25;
    height: 0.2vw;
    width: 79vw;
}
  