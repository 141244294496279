.PricingPageContainer
{
    position: relative;
    width: 100vw;
    height: 100vh;
    top: 0;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: auto; 
}



.PricingTitle
{
    position: absolute;
    color: #000;
    text-align: center;
    font-family: Karantina;
    font-size: 5rem;
    font-style: normal;
    font-weight: 400;
    top: 0rem;
}
.paper-background
{
    position: absolute;
    top: 0;
    width: 100vw;
    object-fit:cover; 
    z-index: -1;
}
.pricing-left-rectangle
{
    position: absolute;
    top:23rem;
    left: 0rem;
    width: 2rem;
    height: 12rem;
    background-color: #F5BC25;
}
.CardContainer
{
    position: absolute;
    top: 12.8rem;
    width: 70rem;
    height: 34rem;
    column-gap: 4rem;
    row-gap: 4rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;  
} 
#disclaimer
{
    position: absolute;
    top: -1rem;
    font-family: Maven;
    font-size: 1.5rem;
}
.Bottom-Rectangle
{
    position: absolute;
    bottom: 0;
    width: 100vw;
    height: 20vw;
    z-index: -1;
    overflow: hidden;
  

}
.Bottom-Rectangle img
{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
@media screen and (min-width:0px) and (max-width:767px) 
{
    .PricingPageContainer
    {
        position: relative;
        overflow-y: none;
    }
    .paper-background
    {
        top: 0vw;
        left: 0vw;
        width: 100vw;
        height: 20vw;
        object-fit: contain;
    }
    .paper-background img
    {
        width: 100%;
        height: 100%;
    }
    .PricingTitle
    {
        font-size: 8vw;
        top: 35%;
        left: 50%;
        transform: translate(-50%,-50%);
    }
    .pricing-left-rectangle
    {
        top:45%;
        left: 0rem;
        width: 2vw;
        height: 13vw;
    }
    .CardContainer
    {
        top: 50%;
        transform: translateY(-50%);
        width: 94vw;
        height: fit-content;
        column-gap: 7vw;
        row-gap: 5vw;
    }
    #disclaimer
    {
        top: 110%;
        color: white;
        font-size: 2.5vw;
    }
}
@media screen and (min-width:768px) and (max-width:1278px) 
{
    .paper-background
    {
        top: 0vw;
        left: 0vw;
        width: 100vw;
        height: 11vw;
        object-fit: contain;
    }
    .PricingTitle
    {
        font-size: 5.5vw;
        top: 2%;
        top: 35%;
        left: 50%;
        transform: translate(-50%,-50%);
    }
    .paper-background img
    {
        width: 100%;
        height: 100%;
    }
    .pricing-left-rectangle
    {
        top:45%;
        left: 0rem;
        width: 2vw;
        height: 13vw;
    }
    .CardContainer
    {
        top: 55%;
        left: 50%;
        transform: translate(-50%,-50%);
        width: 94vw;
        height: fit-content;
        column-gap: 4vw;
        row-gap: 3vw;
    }
    #disclaimer
    {
        top: 110%;
        color: white;
        font-size: 1.5vw;
    }
}
@media screen and (min-width:1279px) 
{
    .paper-background
    {
        top: 0vw;
        width: 100vw;
        height: 10vw;
        object-fit: contain;
    }
    .paper-background img
    {
        width: 100%;
        height: 100%;
    }
    .PricingTitle
    {
        font-size: 5.5vw;
        top: 2%;
        top: 35%;
        left: 50%;
        transform: translate(-50%,-50%);
    }
    .pricing-left-rectangle
    {
        top:45%;
        left: 0rem;
        width: 2vw;
        height: 13vw;
    }
    .CardContainer
    {
        top: 55%;
        left: 50%;
        transform: translate(-50%,-50%);
        width: 94vw;
        height: fit-content;
        column-gap: 4vw;
        row-gap: 3vw;
    }
    #disclaimer
    {
        top: 110%;
        color: white;
        font-size: 1.5vw;
    }
}