.ReviewContainer {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 7vw;
    width: 93vw;
    height: auto;
    display: flex;
    justify-content: center;
    column-gap: 2vw;
    flex-direction: row;
    flex-wrap: nowrap;
    padding-bottom: 1vw;
}

.review-card {
    background-color: white;
    width: 30vw;
    box-sizing: border-box;
    border-radius: 0.5vw;
    padding: 1vw 1vw;
    transition: 0.2s ease-in-out;
}

.review-card:hover {
    scale: 1.05;
    box-shadow: 0 0 20px rgba(255, 215, 0, 0.8);
}



.review-card p {
    margin: 0;
    text-align: center;

}

.review-review {
    font-family: Roboto;
    font-size: 1vw;
}

.review-rating {
    margin-top: 2vw;
    font-weight: bold;
    color: #FFD700;
    text-shadow: 0 0 2px #FFD700, 0 0 2px #FFD700, 0 0 2px #FFD700;
}

.review-author {
    font-size: 1.5vw;
    font-family: 'Staat';
    color: #555;
}

.review-card .quotes-icon {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-size: contain;
    z-index: -1;
}

@media screen and (max-width:768px) {
    .ReviewContainer {
        margin-top: 13vw;
        width: 93vw;
        column-gap: 7vw;
        row-gap: 3vw;
        flex-wrap: wrap;
        padding-bottom: 1vw;
    }

    .review-card {
        width: 42vw;
        border-radius: 0.5vw;
        padding: 1.2vw 1.2vw;
    }

    .review-review {
        font-size: 2.5vw;
    }

    .review-author {
        font-size: 3vw;

    }
}