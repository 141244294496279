.Booking-two {
    position: relative;
    width: 100vw;
    height: 100vh;
}

#back-arrow {
    position: absolute;
    height: 4.5vw;
    width: 4.5vw;
    top: 0;
    left: 0;
    color: gray;
    cursor: pointer;
    transition: 0.2s all ease-in-out;
}

#back-arrow:hover {
    height: 5vw;
    width: 5vw;
    color: white;
}

.FormContainerOne {
    position: absolute;
    width: 76rem;
    height: 32rem;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 1.875rem;
    background: #3A3A3A;
    color: #FFF;
}

.FormContainerOne h1 {
    position: absolute;
    font-family: Morn;
    font-size: 2rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    top: 3.5rem;
    left: 8rem;
}

.FormContainerOne h1:nth-child(4) {
    top: 11.5rem;
}

.FormContainerOne h1:nth-child(5) {
    top: 11.5rem;
    left: 40rem;
}

.FormContainerOne h1:nth-child(8) {
    top: 19rem;
}

.FormContainerOne input,
select {
    position: absolute;
    width: 27rem;
    height: 3rem;
    border: 2px solid #FFF;
    background: rgba(25, 25, 25, 0.40);
    font-family: Morn;
    font-size: 1.7rem;
    top: 6.5rem;
    left: 8rem;
    padding: 0.2rem 0.5rem;
    color: #FFF;
    transition: 0.3s all ease-in-out;
}

.FormContainerOne input:nth-child(2) {
    top: 6.5rem;
    left: 8rem;
}

.FormContainerOne :nth-child(3) {
    top: 6.5rem;
    left: 40rem;
}

.FormContainerOne :nth-child(6) {
    top: 14.5rem;
    left: 8rem;
}

.FormContainerOne :nth-child(7) {
    top: 14.5rem;
    left: 40rem;
}

.FormContainerOne :nth-child(8) {
    top: 17.7rem;
    left: 8rem;
}

.FormContainerOne select {
    top: 22rem;
    left: 8rem;
    width: 60rem;
    height: 3.5rem;
    border-radius: 1.875rem;
    appearance: none;
    /* Remove default styles */
    -webkit-appearance: none;
    cursor: pointer;

}

.FormContainerOne input:hover {
    background-color: #E54545;
}

.FormContainerOne select:hover {
    background-color: #E54545;
}

.FormContainerOne #Submit {
    position: absolute;
    top: 85%;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    width: 10rem;
    height: 4rem;
    border: 3px solid #F5BC25;
    border-radius: 2.5rem;
    font-family: morn;
    color: white;
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    background-color: transparent;
    transition: 0.3s all ease-in-out;
    cursor: pointer;
}

.FormContainerOne #Submit:hover {
    background-color: #F5BC25;
    color: black;
    font-weight: 600;
}

.FormContainerOne #icon {
    position: absolute;
    width: 1.7rem;
    height: 3rem;
    top: 22.5rem;
    left: 64rem;
    cursor: pointer;
}

@media screen and (min-width:0px) and (max-width:768px) {
    #back-arrow {
        position: absolute;
        height: 8vw;
        width: 8vw;
        top: 0;
        left: 0;
        color: gray;
        cursor: pointer;
        transition: 0.2s all ease-in-out;
    }

    .Booking-two {
        width: 100vw;
        height: 90vh;
        overflow: hidden;
        margin-top: 1vw;
    }

    .FormContainerOne {
        width: 78vw;
        height: 80vh;
        margin-top: 10vw;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 1.875vw;
    }

    .FormContainerOne h1 {
        font-size: 5vw;
        top: 6%;
        left: 5%;
    }

    .FormContainerOne h1:nth-child(4) {
        top: 24%;
    }

    .FormContainerOne h1:nth-child(5) {
        top: 42%;
        left: 5%;
    }

    .FormContainerOne h1:nth-child(8) {
        top: 61%;
        left: 5%;
    }

    .FormContainerOne input,
    select {
        width: 30vw;
        /* height: 10vw; */
        font-size: 4.5vw;
        padding: 3vw 0.8vw;
        border-width: 0.2vw;
        border-radius: 2vw;
    }

    .FormContainerOne input:nth-child(2) {
        top: 11%;
        left: 5%;
    }

    .FormContainerOne :nth-child(3) {
        top: 11%;
        left: 56%;
    }

    .FormContainerOne :nth-child(6) {
        top: 29%;
        left: 5%;
        width: 70vw;
    }

    .FormContainerOne :nth-child(7) {
        top: 47%;
        left: 5%;
        width: 70vw;
    }

    .FormContainerOne select {
        top: 66%;
        left: 5%;
        width: 70vw;
        height: 9vw;
        border-radius: 2.5vw;
        font-size: 4vw;
        border-width: 0.2vw;
        padding-left: 1.5vw;
        padding-top: 1vw;
    }

    .FormContainerOne #Submit {
        top: 82%;
        left: 50%;
        transform: translateX(-50%);
        width: 28vw;
        height: 12vw;
        border: 0.2vw solid #F5BC25;
        border-radius: 3vw;
        font-size: 5vw;
    }
}

@media screen and (min-width:767px) and (max-width:1023px) {
    .Booking-two {
        width: 100vw;
        height: 58vw;
        overflow: hidden;
    }

    .FormContainerOne {
        width: 78vw;
        height: 55vw;
        margin-top: 7vw;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 1.875vw;
    }

    .FormContainerOne h1 {
        font-size: 3.2vw;
        top: 8%;
        left: 5%;
    }

    .FormContainerOne h1:nth-child(4) {
        top: 36%;
    }

    .FormContainerOne h1:nth-child(5) {
        top: 36%;
        left: 56%;
    }

    .FormContainerOne h1:nth-child(8) {
        top: 60%;
        left: 5%;
    }

    .FormContainerOne input,
    select {
        width: 30vw;
        height: 4vw;
        font-size: 2.2vw;
        padding: 0.5vw 0.5vw;
        border-width: 0.2vw;
    }

    .FormContainerOne input:nth-child(2) {
        top: 17%;
        left: 5%;
    }

    .FormContainerOne :nth-child(3) {
        top: 17%;
        left: 56%;
    }

    .FormContainerOne :nth-child(6) {
        top: 45%;
        left: 5%;
    }

    .FormContainerOne :nth-child(7) {
        top: 45%;
        left: 56%;
    }

    .FormContainerOne select {
        top: 69%;
        left: 5%;
        width: 70vw;
        height: 5vw;
        border-radius: 2vw;
        font-size: 2.3vw;
        border-width: 0.2vw;
        padding-left: 1vw;
        padding-top: 0.6vw;
    }

    .FormContainerOne #Submit {
        top: 85%;
        left: 50%;
        transform: translateX(-50%);
        width: 14vw;
        height: 5.5vw;
        border: 0.2vw solid #F5BC25;
        border-radius: 2.5vw;
        font-size: 2.7vw;
    }
}

@media screen and (min-width:1024px) {
    .Booking-two {
        width: 100vw;
        height: 79vh;
        overflow: hidden;
    }

    .FormContainerOne {
        width: 78vw;
        height: 33vw;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 1.875vw;
    }

    .FormContainerOne h1 {
        font-size: 2vw;
        top: 12%;
        left: 10.5%;
    }

    .FormContainerOne h1:nth-child(4) {
        top: 37%;
    }

    .FormContainerOne h1:nth-child(5) {
        top: 37%;
        left: 53.5%;
    }

    .FormContainerOne h1:nth-child(8) {
        top: 60%;
    }

    .FormContainerOne input,
    select {
        width: 27vw;
        height: 3.4vw;
        font-size: 1.8vw;
        padding: 0.2vw 0.5vw;
    }

    .FormContainerOne input:nth-child(2) {
        top: 20.5%;
        left: 10.5%;
    }

    .FormContainerOne :nth-child(3) {
        top: 20.5%;
        left: 53.5%;
    }

    .FormContainerOne :nth-child(6) {
        top: 46%;
        left: 10.5%;
    }

    .FormContainerOne :nth-child(7) {
        top: 46%;
        left: 53.5%;
    }

    .FormContainerOne :nth-child(8) {
        top: 55%;
        left: 10.5%;
    }

    .FormContainerOne select {
        top: 70%;
        left: 10.5%;
        width: 63vw;
        height: 3.5vw;
        border-radius: 1.875vw;
        font-size: 1.8vw;
    }

    .FormContainerOne #Submit {
        top: 85%;
        left: 50%;
        transform: translateX(-50%);
        width: 10vw;
        height: 4vw;
        border: 0.2vw solid #F5BC25;
        border-radius: 2.5vw;
        font-size: 1.6vw;
    }

    .FormContainerOne #icon {
        width: 1.7vw;
        height: 3vw;
        top: 71%;
        left: 87%;
    }
}