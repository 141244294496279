#yt-player {
    position: absolute;
    width: 70vw;
    height: 35vw;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

@media screen and (max-width:768px) {
    #yt-player {
        position: relative;
        height: 42vw;
    }
}