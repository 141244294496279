
.Booking-stage-container
{
    width: 100vw;
    height: 20vh;
}
.Numbering
{
    /* position: relative; */
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    margin: 0;
    margin-top: 1rem;
    /* background-color: aqua; */
    
}
.Numbering h1 {
    font-family: Morn;
    color: white;
    font-size: 3rem;
    font-style: normal;
    font-weight: 400;
    filter: drop-shadow(3.2px 3.2px 3.2px rgba(0, 0, 0, 0.6));
    padding: 0.5rem 1.7rem;
    text-align: center;
    border-radius: 50%;
    background-color: red;
    background-color: transparent; /* Default background color */
    border: 3px solid #F5BC25; /* Default border color */
}

.Numbering h1:nth-child(1) {
    background-color: #F5BC25; 
    border: none; 
    color: black;
    padding: 0.5rem 2rem;
}
.Numbering .StageText
{
    background-color: #F5BC25; 
    color: black;
}
.Numbering .Line1
{
    width: 30rem;
    height: 0.1875rem;
    background: #FFF;
    margin: 0;
}
.Numbering .Line2
{
    width: 30rem;
    height: 0.1875rem;
    background: #FFF;
}
.Titles
{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 0.7rem;
    width: 100vw;
}
.Titles h1
{
    display: inline;
    text-align: center;
    color: white;
    font-family: Morn;
    font-size: 2.2rem;
    font-style: normal;
    font-weight: 400;
    margin-right: 14rem;
}
.Titles h1:last-child
{
    margin: 0;
}
@media screen and (max-width: 767px)
{
    .Booking-stage-container
    {

        height: fit-content;
        margin-top: 0;
        padding-top: 1vw;
    }
    .Numbering
    {
        position: relative;
        margin-top: 1.5vw;
        
    }
    .Numbering h1
    {
        font-size: 6vw;
        padding: 0.7vw 3vw;
        border-width: 0.2vw;
    }
    .Numbering h1:nth-child(1) {
        padding: 1vw 3.5vw;
    }
    .Titles
    {
        margin-top: 1vw;
    }
    .Titles h1
    {
        font-size: 2.5vw;
        margin-right: 16vw;
        
    }
    .Numbering .Line1
    {
        width: 26vw;
        height: 0.2vw;
    }
    .Numbering .Line2
    {
        width: 26vw;
        height: 0.2vw;
    }
}
@media screen and (min-width: 768px)
{
    .Numbering
    {
        margin-top: 1vw;
    }
    .Numbering h1
    {
        font-size: 4vw;
        padding: 0.3vw 1.7vw;
        border-width: 0.2vw;
    }
    .Numbering h1:nth-child(1) {
        padding: 0.5vw 2.2vw;
    }
    .Titles
    {
        margin-top: 0.7vw;
    }
    .Titles h1
    {
        font-size: 2vw;
        margin-right: 16vw;
        
    }
    .Numbering .Line1
    {
        width: 26vw;
        height: 0.2vw;
    }
    .Numbering .Line2
    {
        width: 26vw;
        height: 0.2vw;
    }
}