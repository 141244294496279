.login-background {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    background-color: black;
    overflow: none;
}

#loginBG {
    width: 100vw;
    height: 100vh;
    object-fit: cover;
    opacity: 0.6;
}

.login-rectangle {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30vw;
    height: 30vw;
    backdrop-filter: blur(5px);
}


.login-rectangle::before {
    content: "";
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #21201E;
    opacity: 0.9;
}

.logo-container {
    position: absolute;
    width: 100%;
    height: 6vw;
    top: 0%;
}

#MelodiumLogo {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 4.5vw;
    height: 4.5vw;
    transform: translate(-50%, -50%);
}

#login-text {
    position: absolute;
    top: 22%;
    left: 50%;
    font-family: Morn;
    font-size: 1.8vw;
    letter-spacing: 2px;
    color: white;
    transform: translate(-50%, -50%);
}

#login-username {
    position: absolute;
    top: 33%;
    left: 2vw;
    font-size: 2vw;
    color: white;
    z-index: 2;
    font-family: Morn;
}

#login-enter-username {
    position: absolute;
    z-index: 2;
    top: 42%;
    border: 2px solid #F5BC25;
    width: 26vw;
    padding-left: 0.5rem;
    color: white;
    font-size: 1.8vw;
    letter-spacing: 1px;
    font-family: Morn;
    background-color: transparent;
    opacity: 0.8;
    left: 2vw;
    transition: 0.1s all ease-in-out;
}

#login-enter-username:hover {
    backdrop-filter: blur(10px);
}

#login-password {
    position: absolute;
    top: 55%;
    left: 2vw;
    font-size: 2vw;
    color: white;
    z-index: 2;
    font-family: Morn;
}

#login-enter-password {
    position: absolute;
    z-index: 2;
    top: 64%;
    border: 2px solid #F5BC25;
    width: 26vw;
    padding-left: 0.5rem;
    color: white;
    font-size: 1.8vw;
    letter-spacing: 1px;
    font-family: Morn;
    background-color: transparent;
    backdrop-filter: blur(5px);
    opacity: 0.8;
    left: 2vw;
}

#login-enter-password:hover {
    backdrop-filter: blur(5px);
}

.login-submit-container {
    position: absolute;
    top: 90%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 8vw;
    height: 3vw;
    font-size: 2vw;
    background-color: transparent;
    border: 0.2vw solid #F5BC25;
    border-radius: 1vw;
    color: white;
    overflow: hidden;
    cursor: pointer;
    transition: 0.2s all ease-in-out;
}

.login-submit-container:hover {
    background-color: #F5BC25;
    color: black;
}

@media screen and (max-width:768px) {

    .login-rectangle {
        width: 70vw;
        height: 70vw;
    }

    .logo-container {
        height: 12vw;
        top: 1%;
    }

    #MelodiumLogo {
        width: 12vw;
        height: 12vw;
    }

    #login-text {
        top: 24%;
        font-size: 5vw;
    }

    #login-username {
        top: 33%;
        left: 26%;
        transform: translateX(-50%);
        font-size: 4.5vw;
    }

    #login-enter-username {
        top: 42%;
        width: 80%;
        font-size: 4vw;
        opacity: 0.8;
        left: 50%;
        transform: translateX(-50%);
    }

    #login-password {
        top: 60%;
        left: 26%;
        transform: translateX(-50%);
        font-size: 4.5vw;
    }

    #login-enter-password {
        top: 69%;
        width: 80%;
        font-size: 4vw;
        left: 50%;
        transform: translateX(-50%);
    }

    .login-submit-container {
        width: 15vw;
        height: 8vw;
        font-size: 4vw;
        border-radius: 2vw;
    }
}