.dashboard-menu {
  height: 95vh;
  left: 5vh;
  position: fixed;
  top: 3vh;
  width: 14vw;
  background-color: #000000;
  border-radius: 4vw;
  display: flex;
  justify-content: center;
}

.studio-image-text-cont {
  position: relative;
  top: 2vw;
  width: 12vw;
  height: 4.5vw;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.menu-logo {
  height: 4vw;
  width: 4vw;
  object-fit: cover;
  cursor: pointer;
}

.spanMel {
  color: #fffffff7;
  font-family: "Cambria-Regular", Helvetica;
  font-size: 1.6vw;
  font-weight: 400;
  letter-spacing: 0.32px;
  line-height: normal;
  text-align: center;
  position: relative;
  left: 0.5vw;
  cursor: pointer;
}

.dash-menu-line1 {
  position: absolute;
  top: 7.5vw;
  height: 0.1vw;
  width: 12vw;
  background-color: white;
}

.dashboard-menu-container {
  position: absolute;
  height: fit-content;
  width: 12vw;
  top: 9.5vw;
  font-size: 3vw;
  font-family: Morn;
  color: white;
  display: flex;
  left: 50%;
  transform: translateX(-50%);
}

.dashboard-menu-container ul {
  list-style-type: none;
  position: relative;
  left: 0;
  cursor: pointer;
}

.dashboard-menu-container li {
  margin-top: 2.5vw;
  margin-left: 1.8vw;
  position: relative;
  cursor: pointer;
}

.dashboard-menu-container li:first-child {
  margin-top: 0;
  cursor: pointer;
}

.dash-menu-line2 {
  position: absolute;
  top: 31vw;
  height: 0.1vw;
  width: 12vw;
  background-color: white;
}

.dashboard-menu-container2 {
  position: absolute;
  height: fit-content;
  width: 12vw;
  top: 33vw;
  font-size: 2vw;
  font-family: Morn;
  color: white;
  display: flex;
  justify-content: center;
}

.dashboard-menu-container2 ul {
  list-style-type: none;
  position: relative;

  cursor: pointer;
}

.dashboard-menu-container2 li {

  margin-left: 0rem;
  margin-top: 2vw;
  cursor: pointer;
}

a {
  text-decoration: none;
  cursor: pointer;
}

.icon-text {
  cursor: pointer;
  font-size: 1.6vw;
  transition: 0.2s all ease-in-out;
  color: white;
}

.icon-text:hover {
  color: #F5BC25;
}

.dashboard-menu-container2 li:first-child {
  margin-top: 0;
  cursor: pointer;
}

.menu-link {
  text-decoration: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 0.5vw;
  transition: 0.2s all ease-in-out;
  color: white;
}

.menu-link:hover {
  color: #f5bc25;
}

@media screen and (min-width: 0px) and (max-width: 768px) {
  .icon-text:hover {
    color: transparent;
  }

  .menu-link:hover {
    color: transparent;
  }

  .dashboard-menu {
    border-radius: 100px;
    width: 8vh;
    left: 2%;
  }

  .studio-image-text-cont {
    top: 3rem;
    width: 11rem;
    height: 3.5rem;
  }

  .menu-logo {
    height: 6vh;
    width: 6vh;
    top: 10%;
    left: 50%;
    transform: translate(-50%, -70%);
    position: absolute;
  }

  .spanMel {
    display: none;
  }

  .dash-menu-line1 {
    top: 12vh;
    height: 0.1rem;
    width: 6vh;
  }

  .dashboard-menu-container {
    width: 90%;
    height: 57%;
    font-size: 0;
    position: absolute;
    top: 43%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;

  }

  .icon-text {
    font-size: 4.5vh;
    height: 4.4vh;
    width: 4.4vh;
    color: transparent;
  }

  .dashboard-menu-container li {
    margin-top: 7.5vh;
    margin-left: 0rem;
    font-size: 0;

  }

  .dashboard-menu-container li:first-child {
    margin-top: 0.5vh;
    cursor: pointer;
  }

  .dash-menu-line2 {
    top: 72%;
    height: 0.1rem;
    width: 6vh;
  }

  .dashboard-menu-container2 {

    height: 18vh;
    width: 90%;
    top: 83%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .dashboard-menu-container2 li {
    margin-top: 5vh;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
  }

  .dashboard-menu-container2 li:first-child {
    margin-top: -5vh;
    position: absolute;
    top: 0;
    left: 50%;
  }
}