.pagetwocontainer {
    position: relative;
    width: 100vw;
    height: 95vw;
    overflow-x: hidden;
}

.rectangle3 {
    position: relative;
    width: 16.5vw;
    height: 4.5vw;
    top: 6vw;
    background-color: #F5BC25;
    transform: translateX(-100%);
    opacity: 0;
    transition: opacity 1s ease-in-out;
}

.rectangle3.animate {
    opacity: 1;
    animation: slideInLeft 1s forwards;
}

@keyframes slideInLeft {
    to {
        transform: translateX(0);
    }
}

.herotext2 {
    width: 30.5vw;
    height: fit-content;
    color: #F8F8F8;
    font-family: Morn;
    font-size: 3.8vw;
    position: relative;
    font-style: normal;
    margin-top: 8.5vw;
    left: 7.8vw;
    font-weight: 400;
    line-height: 4.5vw;
    /* 123.077% */
    letter-spacing: -0.1vw;
    opacity: 0;
    transition: opacity 1s ease-in-out;

}

.herotext2.animate {
    opacity: 0;
    transform: scale(0.5) translateY(100%);
    animation: fadeInAndScale 1s ease-in-out forwards, slideInFromBottom 1s ease-in-out 1s forwards;
}

@keyframes fadeInAndScale {
    to {
        opacity: 1;
        transform: scale(1);
    }
}

@keyframes slideInFromBottom {
    to {
        transform: translateY(0);
    }
}

.herotext2 span {
    color: #F5BC25;
}


.subtext2 {
    color: #FFF;
    font-family: Maven;
    font-size: 1.3vw;
    font-style: normal;
    font-weight: 550;
    line-height: normal;
    width: 31vw;
    height: fit-content;
    text-align: left;
    margin-top: 4vw;
    left: 7.8vw;
    position: relative;
}

.circle2 {
    position: absolute;
    top: 7vw;
    left: 83vw;
    width: fit-content;
    height: fit-content;
    fill: rgba(245, 188, 37, 0.50);
    backdrop-filter: blur(5px);
    z-index: 3;

}

.circle2 svg {
    width: 11vw;
    height: 11vw;
}

.record1 {
    position: absolute;
    width: 7vw;
    height: 0.2vw;
    top: 35vw;
    background-color: #F5BC25;
    left: 53vw;
}

.record2 {
    position: absolute;
    width: 0.2vw;
    height: 7vw;
    top: 28vw;
    background-color: #F5BC25;
    left: 53vw;
}

.rectangle32 {
    position: absolute;
    width: 0.2vw;
    height: 6.5vw;
    top: 34vw;
    background-color: #ffffff;
    left: 3.6vw;
}

.rectangle33 {
    position: absolute;
    width: 6.5vw;
    height: 0.2vw;
    top: 0;
    background-color: #ffffff;
    left: 6.5vw;
    top: 43vw;
}

.circle23 {
    fill: #F5BC25;
    backdrop-filter: blur(5px);
    position: relative;
    top: 2vw;
    left: 3vw;
    width: fit-content;
}

.circle23 svg {
    height: 2vw;
    width: 2vw;
}

.ytvid.animate {
    transform: translateX(130%);
    animation: slideInFromRight 0.8s ease-in-out 0.4s forwards;
}

.video-two:hover {
    transform: scale(1.1);
}

.video-two.animate {
    transform: translateX(130%);
    animation: slideInFromRight 0.8s ease-in-out 0.4s forwards;
}

@keyframes slideInFromRight {
    to {
        transform: translateX(0);
    }
}

.mikeimagecontainer {
    position: absolute;
    width: 15vw;
    height: 15vw;
    top: 40vw;
    left: 84vw;
    margin: 0;
}

.mike2 {
    position: absolute;
    margin: 0;
    width: 12vw;
    height: 15vw;
}

.mike2:hover {
    transform: scale(1.1);
}

.mic-text-container {
    position: absolute;
    left: -14vw;
    top: 10vw;
    height: 3vw;
    width: 9.5vw;
}

.miketext {
    color: #FFF;
    font-family: Moulpali;
    font-size: 1.4vw;
    font-style: normal;
    font-weight: 400;
    line-height: 4.7vw;
    letter-spacing: -0.05vw;
    width: 31vw;
    height: fit-content;
    text-align: left;
    top: 60%;
    transform: translateY(-50%);
    position: absolute;
}

.miketext span {
    color: #F5BC25;
}

.lewitt {
    position: absolute;
    width: 4vw;
    height: 0.2vw;
    top: 60%;
    background-color: #F5BC25;
    left: 127%;
    transform: translate(-50%, -50%);
}

.rect4 {
    position: absolute;
    z-index: -1;
    left: 86vw;
    width: 14vw;
    height: 4.8vw;
    top: 53vw;
    background-color: #F5BC25;
    transition: opacity 1s ease-in-out;
}

.rect4.animate {
    opacity: 1;
    transform: translateX(100%);
    /* Start from the right side */
    animation: slideInFromRight 0.6s ease-in-out 0.4s forwards;
}

@keyframes slideInFromRight {
    to {
        transform: translateX(0);
    }
}


.circlethree {
    position: absolute;
    width: fit-content;
    top: 52vw;
    left: 2vw;
    fill: rgba(245, 188, 37, 0.50);
    backdrop-filter: blur(5px);
    z-index: 2;
}

.circlethree svg {
    width: 11vw;
    height: 11vw;
}

.pagetwo2imagecontainer {
    position: absolute;
    width: 35vw;
    height: 28vw;
    top: 57vw;
    left: 6vw;

}

.pagethreeimg {
    position: absolute;
    width: 33vw;
    height: 26vw;
    top: 0rem;
    object-fit: cover;
    border-radius: 2vw;
    transition: opacity 3s ease-in-out;
}

.pagethreeimg.animate {
    opacity: 1;
    transform: translateX(-100%);
    animation: slideInLeft 1s forwards;
}

@keyframes slideInLeft {
    to {
        transform: translateX(0);
    }
}

.pagethreeimg:hover {
    transform: scale(1.1);
}

.herotext3 {
    position: absolute;
    width: 30vw;
    height: fit-content;
    color: #F8F8F8;
    font-family: Morn;
    font-size: 3.8vw;
    font-style: normal;
    text-align: right;
    top: 63vw;
    left: 55vw;
    font-weight: 400;
    line-height: 123%;
    letter-spacing: -0.1vw;
    opacity: 0;
    transform: scale(0.5) translateY(100%);
    animation: fadeInAndScale 1s ease-in-out forwards, slideInFromBottom 1s ease-in-out 1s forwards;
}

@keyframes fadeInAndScale {
    to {
        opacity: 1;
        transform: scale(1);
    }
}

@keyframes slideInFromBottom {
    to {
        transform: translateY(0);
    }
}

.herotext3 span {
    color: #F5BC25;
}

.subtext3 {
    color: #FFF;
    font-family: Maven;
    font-size: 1.25vw;
    font-style: normal;
    font-weight: 550;
    line-height: normal;
    width: 31vw;
    height: fit-content;
    text-align: left;
    top: 76vw;
    left: 57vw;
    position: absolute;
}

.rectangle42 {
    position: absolute;
    width: 7vw;
    height: 0.25vw;
    top: 90vw;
    background-color: #ffffff;
    left: 83vw;
}

.circle33 {
    fill: #F5BC25;
    backdrop-filter: blur(5px);
    position: absolute;
    top: 89vw;
    left: 92vw;
}

.circle33 svg {
    width: 2.2vw;
    height: 2.2vw;
}

.rectangle41 {
    position: absolute;
    width: 0.25vw;
    height: 7vw;
    top: 80.5vw;
    background-color: #ffffff;
    left: 92.7vw;
}




.control {
    color: #FFF;
    font-family: Moulpali;
    font-size: 1.2vw;
    font-style: normal;
    font-weight: 400;
    line-height: 5vw;
    letter-spacing: -0.05vw;
    width: 31vw;
    height: fit-content;
    text-align: left;
    top: 82.6vw;
    left: 27.7vw;
    position: absolute;
}

.control span {
    color: #F5BC25;
}

.control1 {
    position: absolute;
    width: 4vw;
    height: 0.2vw;
    top: 85vw;
    background-color: #F5BC25;
    left: 34.5vw;
}

.melodiumcontainer {
    position: absolute;
    top: 90vw;
    left: 6vw;
    width: 9vw;
    height: 1.5vw;
}

.melodium {
    text-align: center;
    font-weight: 700;
    line-height: normal;
    color: #D9D9D9;
    font-family: Mont;
    font-size: 1vw;
    font-style: normal;
    font-weight: 700;
    letter-spacing: 3.6px;
    transform: translateX(-100%);
    animation: slideInLeft 1s forwards;
}

@keyframes slideInLeft {
    to {
        transform: translateX(0);
    }
}

.melodium span {
    color: #F5BC25;
}

.pagetwoimg {
    position: absolute;
    width: 35vw;
    height: 21vw;
    left: 55vw;
    top: 12vw;
}

@media screen and (min-width:0px) and (max-width:768px) {
    .pagetwocontainer {
        height: fit-content;
        overflow: hidden;
    }

    .page21container {
        position: relative;
        width: 100%;
        height: 50%;
    }

    .page22container {
        position: relative;
        margin-top: 19vw;
        width: 100%;
        height: 50%;
    }

    .rectangle3 {
        position: relative;
        width: 28vw;
        height: 8vw;
        margin-top: 6vw;
        background-color: #F5BC25;
        transform: translateX(-100%);
        opacity: 0;
        transition: opacity 1s ease-in-out;
    }

    .rectangle3.animate {
        opacity: 1;
        animation: slideInLeft 1s forwards;
    }

    @keyframes slideInLeft {
        to {
            transform: translateX(0);
        }
    }

    .herotext-one {
        margin-top: 15vw;
        left: 50%;
        transform: translateX(-50%);
        position: relative;
        height: fit-content;
        width: fit-content;
    }

    .herotext2 {
        width: 79vw;
        text-align: left;
        height: fit-content;
        color: #F8F8F8;
        font-family: Morn;
        font-size: 9vw;
        position: relative;
        font-style: normal;
        margin-top: 8.5vw;
        transform: translateX(-50%);
        font-weight: 400;
        line-height: 100%;
        letter-spacing: -0.1vw;
        opacity: 0;
        left: 0;
        transition: opacity 1s ease-in-out;
    }

    .herotext2.animate {
        opacity: 0;
        transform: scale(0.5) translateY(100%);
        animation: fadeInAndScale 1s ease-in-out forwards, slideInFromBottom 1s ease-in-out 1s forwards;
    }

    @keyframes fadeInAndScale {
        to {
            opacity: 1;
            transform: scale(1);
        }
    }

    @keyframes slideInFromBottom {
        to {
            transform: translateY(0);
        }
    }

    .herotext2 span {
        color: #F5BC25;
    }


    .subtext2 {
        color: #FFF;
        font-family: Maven;
        font-size: 4.2vw;
        font-style: normal;
        font-weight: 550;
        line-height: normal;
        width: 84vw;
        height: fit-content;
        text-align: left;
        margin-top: 8vw;
        left: 0;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
    }

    .rectangle32 {
        position: absolute;
        width: 30vw;
        height: 0.5vw;
        top: 50%;
        left: 75%;
        transform: translate(-50%, -50%);
        background-color: #ffffff;
    }

    .line-circle-divider-container-one {
        position: relative;

        margin-top: 10vw;
        height: fit-content;
        width: 100vw;
    }

    .rectangle33 {
        position: absolute;
        width: 30vw;
        height: 0.5vw;
        top: 50%;
        left: 25%;
        transform: translate(-50%, -50%);
        background-color: #ffffff;
    }

    .circle23 {
        fill: #F5BC25;
        backdrop-filter: blur(5px);
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: fit-content;
    }

    .circle23 svg {
        height: 6vw;
        width: 6vw;
    }

    .circle2 {
        position: absolute;
        margin-top: -8vw;
        left: 83%;
        width: fit-content;
        height: fit-content;
        fill: rgba(245, 188, 37, 0.50);
        backdrop-filter: blur(5px);
        z-index: 3;

    }

    .circle2 svg {
        width: 14vw;
        height: 14vw;
    }

    .pagetwoimg {
        position: relative;
        width: 85%;
        height: 81%;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }

    /* .pagetwoimg iframe {
        width: 100%;
        height: 100%;
    } */

    .video-one-container {
        position: relative;
        margin-top: 16vw;
        height: 50vw;
        width: 80vw;
        left: 50%;
        transform: translateX(-50%);
    }

    .record1 {
        position: absolute;
        width: 30%;
        height: 0.8%;
        top: 100%;
        background-color: #F5BC25;
        left: 0;
    }

    .record2 {
        position: absolute;
        width: 0.7%;
        height: 38%;
        top: 62%;
        background-color: #F5BC25;
        left: 0;
    }

    .ytvid.animate {
        transform: translateX(130%);
        animation: slideInFromRight 0.8s ease-in-out 0.4s forwards;
    }

    .video-two:hover {
        transform: scale(1.1);
    }

    .video-two.animate {
        transform: translateX(130%);
        animation: slideInFromRight 0.8s ease-in-out 0.4s forwards;
    }

    @keyframes slideInFromRight {
        to {
            transform: translateX(0);
        }
    }



    .mikeimagecontainer {
        position: relative;
        width: 15vw;
        height: 1vw;
        top: -18vw;
        left: 65%;
        margin: 0;
        z-index: 3;
    }

    .mike2 {
        position: absolute;
        margin: 0;
        width: 26vw;
        height: 32vw;
    }

    .mike2:hover {
        transform: scale(1.1);
    }

    .mic-text-container {
        position: absolute;
        top: 26vw;
        left: -35vw;
        height: 5vw;
        width: 33.5vw;

    }

    .miketext {
        color: #FFF;
        font-family: Moulpali;
        font-size: 4.3vw;
        font-style: normal;
        font-weight: 400;
        line-height: 4.7vw;
        letter-spacing: -0.05vw;
        width: 35vw;
        height: fit-content;
        text-align: left;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
    }

    .miketext span {
        color: #F5BC25;
    }

    .lewitt {
        position: absolute;
        width: 7vw;
        height: 0.4vw;
        top: 50%;
        transform: translateY(-50%);
        background-color: #F5BC25;
        left: 94%;
    }

    .rect4 {
        position: relative;
        width: 28vw;
        height: 8vw;
        margin-top: 0vw;
        top: 8vw;
        left: 72vw;
        background-color: #F5BC25;
        transform: translateX(-100%);
        opacity: 0;
        transition: opacity 1s ease-in-out;
        z-index: 1;
    }

    .hero-text-two {
        margin-top: 15vw;
        left: 50%;
        transform: translateX(-50%);
        position: relative;
        height: fit-content;
        width: fit-content;
    }

    .rect4.animate {
        opacity: 1;
        transform: translateX(100%);
        animation: slideInFromRight 0.6s ease-in-out 0.4s forwards;
    }

    @keyframes slideInFromRight {
        to {
            transform: translateX(0);
        }
    }

    .line-circle-divider2 {
        position: relative;
        margin-top: 11.5vw;
        top: 0;
        height: fit-content;
        width: 100vw;
    }

    .rectangle42 {
        position: absolute;
        width: 30vw;
        height: 0.5vw;
        top: 50%;
        left: 75%;
        transform: translate(-50%, -50%);
        background-color: #ffffff;
    }

    .rectangle41 {
        position: absolute;
        width: 30vw;
        height: 0.5vw;
        top: 50%;
        left: 25%;
        transform: translate(-50%, -50%);
        background-color: #ffffff;
    }

    .circle33 {
        fill: #F5BC25;
        backdrop-filter: blur(5px);
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: fit-content;
    }

    .circle33 svg {
        height: 6vw;
        width: 6vw;
    }

    .control-image-container {
        position: relative;
        margin-top: 24.5vw;
        height: 50vw;
        width: 80vw;
        left: 50%;
        transform: translateX(-50%);
    }

    .pagetwo2imagecontainer {
        position: absolute;
        width: 85%;
        height: 85%;
        top: 40%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .pagethreeimg {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0rem;
        object-fit: cover;
        border-radius: 2vw;
        transition: opacity 3s ease-in-out;
    }

    .circlethree {
        position: absolute;
        top: 0;
        margin-top: -6vw;
        left: 83%;
        width: fit-content;
        height: fit-content;
        fill: rgba(245, 188, 37, 0.50);
        backdrop-filter: blur(5px);
        z-index: 3;

    }

    .circlethree svg {
        width: 14vw;
        height: 14vw;
    }

    .pagethreeimg.animate {
        opacity: 1;
        transform: translateX(-100%);
        animation: slideInLeft 1s forwards;
    }

    @keyframes slideInLeft {
        to {
            transform: translateX(0);
        }
    }

    .pagethreeimg:hover {
        transform: scale(1.1);
    }

    .herotext3 {
        width: 80vw;
        text-align: right;
        height: fit-content;
        color: #F8F8F8;
        font-family: Morn;
        font-size: 8vw;
        position: relative;
        font-style: normal;
        margin-top: 0vw;
        left: 0;
        transform: translateX(-50%);
        font-weight: 400;
        line-height: 100%;
        letter-spacing: -0.1vw;
        opacity: 0;
        transition: opacity 1s ease-in-out;
        top: 2%;
    }

    @keyframes fadeInAndScale {
        to {
            opacity: 1;
            transform: scale(1);
        }
    }

    @keyframes slideInFromBottom {
        to {
            transform: translateY(0);
        }
    }

    .herotext3 span {
        color: #F5BC25;
    }

    .subtext3 {
        color: #FFF;
        font-family: Maven;
        font-size: 4vw;
        font-style: normal;
        font-weight: 550;
        line-height: normal;
        width: 84vw;
        height: fit-content;
        text-align: left;
        margin-top: 8vw;
        left: 0;
        top: 0;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
    }

    .control-text-container {
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        height: 10%;
        width: 86%;
        top: 90%;
    }

    .control {
        color: #FFF;
        font-family: Moulpali;
        font-size: 4vw;
        font-style: normal;
        font-weight: 400;
        line-height: 5vw;
        letter-spacing: -0.05vw;
        width: fit-content;
        height: fit-content;
        text-align: left;
        top: 50%;
        transform: translateY(-50%);
        left: 55.5%;
        position: absolute;
    }

    .control span {
        color: #F5BC25;
    }

    .control1 {
        position: absolute;
        width: 10%;
        height: 0.5vw;
        top: 50%;
        background-color: #F5BC25;
        left: 89%;
    }

    .melodiumcontainer {

        display: none;
    }

    @keyframes slideInLeft {
        to {
            transform: translateX(0);
        }
    }

}