:root {  
    --pi: 22 / 7;
    --size: 120;
    --stroke: 5;
    --radius: calc((var(--size) / 2) - (var(--stroke) / 2));
    --circumference: calc(2 * var(--pi) * var(--radius));
    --color: #F5BC25;
  }
  
 .BookingConfirmedContainer
 {
  width: 100vw;
  height: 100vh;
  position: relative;
 }
 .confirm {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30vw;
  height: 30vw;
}
  
  .confirm:after {
    content: "";
    left: 50%;
    top: 50%;
    width: 100%;
    height: 100%;
    transform: translate(-50%, -50%);
    position: absolute;
    border-radius: 50%;
    animation: pulse ease-in-out 1s 2s;
  }
  
  .confirm:before {
    content: "";
    left: 50%;
    top: 50%;
    width: 100%;
    height: 100%;
    transform: translate(-50%, -50%);
    position: absolute;
    border-radius: 50%;
    animation: pulse ease-in-out 1s 2.5s;
  }
  
  .confirm__progress {
    transform: rotate(-90deg);
    width: 30vw;
    height: 30vw;
  }
  
  .confirm__value {
    stroke: var(--color);
    stroke-linecap: round;
    stroke-width: var(--stroke);
    fill: none;
    stroke-dasharray: var(--circumference);
    animation: confirmprogress 2s alternate;
  }
  .confirm__inner {
    border-radius: 50%;
    background: var(--color);
    width: 75%;
    height: 75%;
    top: 12.5%;
    left: 12.5%;
    transform-origin: 50% 50%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10vw;
    animation: rotate 2s, confirminner alternate 1s 2s;
    animation-fill-mode: forwards;
  }
  
  .confirm__inner:after {
    content: "\231B";
    position: absolute;
    animation: confirminnerafter alternate 1s 2s;
    animation-fill-mode: forwards;
  }
  
  .action {
    margin-top: 3em;
  }
  
  @keyframes confirmprogress { /* Firefox */
    from {
      stroke-dashoffset: var(--circumference);
    }
    to {
      stroke-dashoffset: 0;
    }
  }
  
  @supports (cx: 0) {
    @keyframes confirmprogress { /* Chrome */
      from {
        stroke-dashoffset: calc(var(--circumference) *1px);
      }
      to {
        stroke-dashoffset: 0;
      }
    }
  }
  
  @keyframes pulse {
    0% {
      transform: translate(-50%, -50%) scale(1, 1);
      opacity: 0;
      border: 5px solid var(--color);
    }
    50% {
      opacity: 1;
    }
    100% {
      border: 1px solid var(--color);
      transform: translate(-50%, -50%) scale(1.3, 1.3);
      opacity: 0;
    }
  }
  
  @keyframes confirminnerafter {
    from {
      content: "\231B";
    }
    to {
      content: "\2713";
      transform: rotateY(180deg);
    }
  }
  
  @keyframes confirminner {
    to {
      transform: rotateY(180deg);
    }
  }
  
  @keyframes rotate {
    to {
      transform: rotate(720deg);
    }
  }
  .booking-confirmed-title {
    font-size: 3vw;
    position: relative;
    display: block;
    width: 70vw;
    justify-content: center;
    text-align: center;
    left: 50%;
    top: 80%;
    transform: translate(-50%, -50%);
    animation: bookingtitle 2s forwards; /* Add this animation */
  }
  .booking-confirmed-title h1
  {
    font-family: Morn;
    color: white;
    
  }
  .booking-confirmed-title span
  {
    color: #F5BC25;
  }
  
  .hidden {
    display: none;
  }
  
  @keyframes bookingtitle {
    from {
      opacity: 0; /* Start with opacity 0 to fade in */
    }
    to {
      opacity: 1; /* End with opacity 1 to make it fully visible */
    }
  }
  .booking-confirmed-fade-out {
    animation: fadeOut 1s ease-out;
  }
  
  @keyframes fadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  @media screen and (min-width:0) and (max-width:767px) 
  {
    .confirm {
      position: absolute;
      top: 40%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      justify-content: center;
      align-items: center;
      width: 60vw;
      height: 60vw;
    } 
    .confirm__inner {
      border-radius: 50%;
      background: var(--color);
      width: 75%;
      height: 75%;
      top: 12.5%;
      left: 12.5%;
      transform-origin: 50% 50%;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 20vw;
      animation: rotate 2s, confirminner alternate 1s 2s;
      animation-fill-mode: forwards;
    }
    .booking-confirmed-title {
      font-size: 4vw;
      position: relative;
      display: block;
      width: 75vw;
      justify-content: center;
      text-align: center;
      top:70%;
      left: 50%;
      margin-top: 1vw;
      transform: translate(-50%,-50%);
      animation: bookingtitle 2s forwards; /* Add this animation */
    }
  }

  