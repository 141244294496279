.pbookingcont
{
  width: 100vw;
  height: 100vh;
    overflow: hidden;
} 

.Pbookingcontainer
{
    position: relative;
    margin: 0;
    padding: 0;
    overflow: hidden;
    height: 100vh;
    width: 100vw;
}
.Ptable-scroll
{
    position: relative;
    display: flex;
    justify-content: center;
    margin-left: 0rem;
    width: 79vw;
    height: 39vw;
    left: 19vw;
    top: 3vw;
}
.Ptable-scroll h1
{
    font-family: Morn;
    text-align: center;
    font-size: 51vw;
    margin-left: 30vw;
}
table {
    table-layout: fixed;
    width: 78vw;
    height: 39vw;
    color: white;
    border-radius: 3vw;
    background: rgba(217, 217, 217, 0.19);
    /* background-color: red; */
    padding: 2vw;
    text-align: center;
}
 tbody td {
    white-space: normal; 
    overflow: hidden;
    word-wrap: break-word;
    text-overflow: ellipsis;
    min-width: 8vw;
    
}
thead tr
{
    border-spacing: 0 2vw;
}
tr
{
    border-collapse: collapse;
}
  td {
    border: 0.1vw solid #ccc;
    border-right: 0.1vw solid #ccc;
    padding: 0.8vw; 
    font-size: 1vw;
    max-width: 8.15vw;
    
}
tbody {
    overflow-y: scroll;
    position: absolute;
    height: 33vw;
    display: block; 
}

.PHeaderTitles th {
    background: #1A1818;

    font-family: Morn;
    color: #F5BC25;
    font-size: 1.3vw;
    border-collapse: collapse;
    text-align: center;
    padding: 0.8vw 0rem; 
}
.PHeaderTitles th:first-child{
    border-radius: 2vw 0 0 2vw;
}
.PHeaderTitles th:last-child{
    border-radius: 0px 2vw 2vw 0px;
}

.PUtilityBar
{
    position: relative;
    left: 19vw;
    top:2.2vw;
    width: 79vw;
    height: 4vw;
    color: white;
    background-color: #1A1818;
    border-radius: 3rem;
}
#Psearch-bar
{
    position: relative;
    background-color: black;
    width: 12vw;
    height: 2vw;
    color: #ccc;
    font-family: Morn;
    font-size: 1vw;
    border-radius: 0.6vw;
    padding-left: 0.2vw;
    margin-top: 0.7vw;
    margin-left: 1vw;
    box-shadow: none;
}
#Psearch-button
{
    position: relative;
    background-color: #F5BC25;
    width: 7vw;
    height: 2vw;
    color: black;
    font-weight: 700;
    font-family: Morn;
    font-size: 1vw;
    border-radius: 0.6vw;
    margin-top: 0.8vw;
    margin-left: 0.3vw;
    cursor: pointer;
    transition: 0.3s all ease-in-out;

}
#Psearch-button:hover
{
  background-color: white;
}
.Psorting-booking-buttons
{
    position: absolute;
    display: flex;
    justify-content: center;
    top: 0.6vw;
    margin-left: 22vw;
    border-radius: 1vw;
    width: 30vw;
    height: 2.5vw;
    background-color: rgba(217, 217, 217, 0.19);
}
.Psorting-booking-buttons > *
{
    margin-left: 1.5vw;
}
#Ptoday-booking-button
{
    background-color: #1A1818;
    color: #F5BC25;
    font-family: Morn;
    padding: 0.2vw 0.9vw;
    border-radius: 0.7vw;
    margin-top: 0.2vw;
    margin-left: 0rem;
    font-size: 0.8vw;
    width: 10vw;
    height: 2vw;
    cursor: pointer;
    transition: 0.3s all ease-in-out;
}
#Ptoday-booking-button:hover
{
  background-color: #F5BC25;
  color: black;
  font-weight: 700;
}
#Preset-button
{
    background-color: #1A1818;
    color: #F5BC25;
    font-family: Morn;
    font-size: 1vw;
    padding: 0.2vw 0.9vw;
    border-radius: 0.7vw;
    margin-top: 0.2vw;
    height: 2vw;
    cursor: pointer;
    width: 5vw;
    transition: 0.3s all ease-in-out;
}
#Preset-button:hover
{
  background-color: #F5BC25;
  color: black;
  font-weight: 700;
}
#PBookingDateSelect
{
    background-color: #1A1818;
    color: #F5BC25;
    font-family: Morn;
    padding: 0.2vw 1vw;
    border-radius: 0.7vw;
    margin-top: 0.3vw;
    height: 1.8vw;
    font-size: 0.9vw;
    width: 10vw;
    color-scheme: dark;
    cursor: pointer;
    transition: 0.3s all ease-in-out;
}
#PBookingDateSelect:hover
{
  background-color: #F5BC25;
  color: black;
  font-weight: 700;
  color-scheme: light;
}
.PStudioButtonD
  {
    position:absolute;
    width: 7vw;
    border:2px solid #F5BC25;
    background-color: transparent;
    font-family: 'LeagueGothic';
    cursor: pointer;
    height: 2.2vw;
    color: white;
    font-weight: 700;
    font-family: Morn;
    font-size: 1vw;
    border-radius: 0.6vw;
    top: 0.8vw;
    margin-left: 54vw;
    letter-spacing: 0.128vw;
    transition: 0.2s all ease-in-out;
  }

  .PStudioButtonD:hover
  {
    background-color: #F5BC25;
    color: black;
  }
  

  .PJamRoomButtonD
  {
    position:absolute;
    width: 7vw;
    border:2px solid #F5BC25;
    background-color: transparent;
    font-family: 'LeagueGothic';
    cursor: pointer;
    height: 2.2vw;
    color: white;
    font-weight: 700;
    font-family: Morn;
    font-size: 0.9vw;
    border-radius: 0.6vw;
    top: 0.8vw;
    margin-left: 62vw;
    letter-spacing: 0.128vw;
    transition: 0.2s all ease-in-out;
  }

  .PJamRoomButtonD:hover
  {
    background-color: #F5BC25;
    color: black;
  }

  .BlockButton
  {
    position:absolute;
    width: 7vw;
    border:2px solid #F5BC25;
    background-color: transparent;
    font-family: 'LeagueGothic';
    cursor: pointer;
    height: 2.2vw;
    color: white;
    font-weight: 700;
    font-family: Morn;
    font-size: 1vw;
    border-radius: 0.6vw;
    top: 0.8vw;
    margin-left: 70vw;
    letter-spacing: 0.128vw;
    transition: 0.2s all ease-in-out;
  }

  .BlockButton:hover
  {
    background-color: #F5BC25;
    color: black;
  }
  .Pedit-div
  {
    position: absolute;
    height: 15vw;
    width: 70vw;
    left:38vw;
    top: 55%;
    left: 50%;
    transform: translate(-50%,-50%);
  }
  .dateConfirmcontainer{
    position: absolute;
    top: 0%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 50vw;
    height: fit-content;
  }
  #PEditSelectDate
  {
    background-color: #1A1818;
    width: 20vw;
    height: 3vw;
    font-size: 2vw;
    color: #F5BC25;
    font-family: Morn;
    padding: 0.2vw 1vw;
    border-radius: 0.7vw;
    margin-top: 0vw;
    color-scheme: dark;
    cursor: pointer;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
  }
  #save-button
  {
    position: absolute;
    background-color: #F5BC25;
    font-family: Morn;
    font-size: 1.5vw;
    height: 3.5vw;
    top: -0.2rem;
    left: 80%;
    transform: translateX(-50%);
    cursor: pointer;
    border-radius: 1rem;
  }
  .PDashTimeSlots
  {
    position: absolute; 
    top: 28%; 
    left: 50%; 
    transform: translate(-50%, -50%);
    margin-top: 5vw;
    display: flex;
    flex-wrap: wrap;
    width:  71vw;
    align-items: center; 
    justify-content: center;
    color: white;
    font-family: Morn;
    font-size: 1vw;
  }

  .PDashTimeSlots > * {
    margin: 1vw 1vw; 
  }
  #Pedit-button
  {
    font-family: Morn;
    background-color: #F5BC25;
    border: 1vw;
    font-weight: 600;
    cursor: pointer;
    width: 7vw;
    font-size: 0.8vw;
    height: 1.2vw;
  }
  #delete-button
  {
    font-family: Morn;
    background-color: #F5BC25;
    border: none;
    font-weight: 600;
    margin-left: 0.2vw;
    cursor: pointer;
    width: 3.2vw;
    font-size: 0.9vw;
    height: 1.2vw;
  }
  .Slot
  {
      display: flex;
      align-items: center; 
      justify-content: center; 
      width: 15rem;
      height: 3.5rem;
      border-radius: 1.5rem;
      background: #D9D9D9;
      color: black;
      font-family: 'Inter';
      font-size: 1.3rem;
      font-style: normal;
      font-weight: 700;
      transition: all 0.2s ease-in-out;
      cursor: pointer;
  }
  .Slot:hover
  {
    background-color: #F5BC25;
  }
  .selected
  {
    background-color: #F5BC25;
  }
  @media screen and (min-width: 0px) and (max-width: 768px) 
  {
    .PUtilityBar
    {
        left: 17vw;
        top:3%;
        width: 80vw;
        height: 7vw;
        border-radius: 10vw;
        display: flex;
        align-items: center;
    }
    #Psearch-bar
    {
        width: 60%;
        height: 3.5vw;
        font-size: 2vw;
        border-radius: 0.4vw;
        padding: 0.1vw 0.3vw;
        top:0;
        margin-top: 0.1vw;
        margin-left: 1.7vw;
    }
    #Psearch-button
    {
        /* width: 4vw; */
        height: 3.5vw;
        font-size: 1.5vw;
        border-radius: 0.4vw;
        margin-top: 0.1vh;
        top: 0;
        margin-left: 0.2vw;
    }
    .Psorting-booking-buttons
    {
        top: 0.7vw;
        margin-left: 32vw;
        margin-top: 1vw;
        border-radius: 0.4vw;
        width: 34.5vw;
        height: 4vw;
    }
    .Psorting-booking-buttons > *
    {
        margin-left: 2vw;
    }
    #Ptoday-booking-button
    {
      padding: 0.1vw 0.3vw;
      border-radius: 0.4vw;
      margin-top: 0.5vw;
      height: 3vw;
      font-size: 1.8vw;
      width: 7vw;
      left: 0;
      margin-left: 0vw;
    }
    #Preset-button
    {
        padding: 0.1vw 0.3vw;
        border-radius: 0.4vw;
        margin-top: 0.5vw;
        height: 3vw;
        font-size: 1.8vw;
        width: 7vw;
        left: 0;
        margin-left: 0.5vw;
    }
    #PBookingDateSelect
    {
        padding: 0.1vw 0.3vw;
        border-radius: 0.4vw;
        margin-top: 0.8vw;
        margin-left: 0.5vw;
        height: 3vw;
        font-size: 1.8vw;
        width: 17vw;
    }
    .PStudioButtonD
      {
       display: none;
      }
      .PJamRoomButtonD
      {
        display: none;
      }
      .BlockButton
      {
        width: 8vw;
        height: 4vw;
        font-size: 1.8vw;
        border-radius: 0.4vw;
        top: 1.8vw;
        margin-left: 69vw;
        letter-spacing: 0rem;
        padding: 0;
      }
      .Ptable-scroll
      {
        margin-left: 0vw;
        width: 80vw;
        height: 88%;
        left: 18vw;
        top: 4vh;
        border-radius: 1vw;
      }
      table {
        width: 100%;
        height: 100%;
        border-radius: 1vw;
        padding: 0.5vw;
        
    }
        tbody td {
          min-width: 8.1vw;
          
      }
        td {
          border: 0.1vw solid #ccc;
          border-right: 1px solid #ccc;
          padding: 0rem; 
          font-size: 1.2vw;
          max-width: 8.7vw;
          
      }
      tbody {
          height: 95%;
      }
      tbody tr {
          margin-top: 0; 
          
      } 
      .PHeaderTitles th {
          font-size: 2vw;
          margin-bottom: 1vw;
          margin-top: 15px;
          padding: 0.1vw 0rem; 
      }
      .PHeaderTitles th:first-child{
          border-radius: 30px 0 0 30px;
      }
      .PHeaderTitles th:last-child{
          border-radius: 0px 30px 30px 0px;
      }
      #Pedit-button
    {
      border: 3px;
      font-size: 1vw;
      padding: 0.1rem 0.1rem;
      width: 10vw;
    }
    #delete-button
    {
      font-size: 1vw;
      padding: 0.1rem 0.1rem;
      margin-top: 0.5vh;
    }
    .Pedit-div {
      height: 22%;
      width: 37.5%;
      top: 30%;
      left: 50%;
      transform: translate(-50%, -50%);
    }    
    #PEditSelectDate
    {
      width: 25vw;
      height: 40%;
      font-size: 5rem;
      padding: 0.2rem 1rem;
      border-radius: 0.7rem;
      margin-top: 7rem;
    }
    .Slot
    {
        width: 25vw;
        font-size: 2.2vw;
        height: 6vw;
        border-radius: 1.6vw;
        border-width: 0.2vw;
        margin:0;
        color: black;

    }
    .PDashTimeSlots
    {
      top: 85%; 
      left: 50%; 
      transform: translate(-50%, -50%);
      margin-top: 5vw;
      width:  70vw;
      font-size: 3vw;
      display: flex;
    flex-wrap: wrap;
    }

    .PDashTimeSlots > * {
      margin: 2vw 2vw; 
    }
    .dateConfirmcontainer{
      position: absolute;
      top: 0%;
      left: 50%;
      transform: translate(-50%,-50%);
      height: fit-content;
      background-color: red;
    }
    #PEditSelectDate
    {
      background-color: #1A1818;
      width: 40vw;
      height: 10vw;
      font-size: 4vw;
      color: #F5BC25;
      font-family: Morn;
      padding: 0.2vw 1vw;
      border-radius: 0.7vw;
      margin-top: 0vw;
      color-scheme: dark;
      cursor: pointer;
      left: 37%;
      position: absolute;
      transform: translateX(-50%);
    }
    #save-button
    {
      position: absolute;
      background-color: #F5BC25;
      font-family: Morn;
      font-size: 4vw;
      width: 12vw;
      height: 10vw;
      top: 0rem;
      left: 90%;
      transform: translateX(-50%);
      cursor: pointer;
      border-radius: 1vw;
    }
  }
