.ContactPageContainer {
  position: relative;
  width: 100vw;
  height: 44vw;
  padding-top: 0;
  margin-top: 7vw;
}

.contact-us-text-container {
  position: relative;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.contact-us-text {
  color: #FFF;
  font-family: staat;
  font-size: 4.3vw;
  letter-spacing: 0.5vw;
  text-align: center;
  font-style: normal;
  font-weight: 400;
  line-height: 161.4%;
}

.content-container {
  display: flex;
  flex-direction: row;
}

.information-container {
  position: relative;
  height: fit-content;
  width: 56vw;
  top: 2vw;
}

.LocationContainer {
  position: relative;
  height: fit-content;
  width: fit-content;
  margin-top: 0vw;
  padding-left: 4vw;
  display: block;
}

.location-icon {
  position: relative;
  margin-top: 2vw;
  height: 3vw;
  width: 3vw;
  display: inline-block;
}

.location-icon svg {
  height: 3.5vw;
  width: 3.5vw;
}

.location-text-container {
  position: relative;
  margin-left: 2vw;
  margin-top: 0vw;
  width: 13vw;
  height: 3.3vw;
  display: inline-block;
}

.location-text {
  position: absolute;
  display: inline-block;
  height: 2vw;
}

.location-text a {
  font-size: 2vw;
  letter-spacing: 0.2vw;
  line-height: 0vh;
  text-align: center;
  font-family: Mont;
  font-style: normal;
  font-weight: 500;
  line-height: 161.4%;
  color: #F5BC25;
  text-decoration: none;
}

.location-subtext {
  position: relative;
  display: block;
  width: 38vw;
  left: 5vw;
  height: fit-content;
  line-height: 0.5vw;
  margin-top: 1vw;
  text-align: left;
  font-family: mont;
}

.location-subtext h1 a {
  font-size: 1.4vw;
  letter-spacing: 0.4vw;
  line-height: 0vh;
  text-align: center;
  font-family: Mont;
  font-style: normal;
  font-weight: 500;
  line-height: 161.4%;
  letter-spacing: 0vw;
  color: white;
  text-decoration: none;
}

.call-container {

  position: relative;
  height: fit-content;
  width: fit-content;
  margin-top: 1.5vw;
  padding-left: 4.1vw;
  display: block;
}

.phone-icon {
  position: relative;
  margin-top: 1vw;
  height: 3vw;
  width: 3vw;
  display: inline-block;
}

.phone-icon svg {
  height: 3vw;
  width: 3vw;
}

.call-us-container {
  position: relative;
  margin-left: 2vw;
  margin-top: 2vw;
  width: 18vw;
  height: 3vw;
  display: inline-block;
}

.call-us-text {
  color: #F5BC25;
  position: absolute;
  display: inline-block;
  font-size: 2vw;
  letter-spacing: 0.2vw;
  line-height: 0vh;
  text-align: center;
  font-family: Mont;
  font-style: normal;
  font-weight: 500;
  line-height: 161.4%;
}

.phone-number-container {
  left: 5vw;
  position: relative;
  display: block;
  width: fit-content;
  height: fit-content;
  line-height: 1vw;
  margin-top: 1vw;
  text-align: left;
  color: white;
  font-family: Mont;
}

.phone-number-container h1 {
  font-size: 1.4vw;
  letter-spacing: 0.4vw;
  line-height: 0vh;
  text-align: center;
  font-family: Mont;
  font-style: normal;
  font-weight: 500;
  line-height: 161.4%;
  letter-spacing: 0vw;

}

.EmailContainer {
  position: relative;
  height: fit-content;
  width: fit-content;
  margin-top: 1.5vw;
  padding-left: 4vw;
}

.email-icon {
  position: relative;
  height: 3vw;
  width: 3vw;
  display: inline-block;
}

.email-icon svg {
  height: 3vw;
  width: 3vw;
}

.email-text-container {
  position: relative;
  margin-left: 2vw;
  margin-top: 2vw;
  width: 23vw;
  height: 3vw;
  justify-content: center;
  align-items: center;
  display: inline-block;
}

.email-text {
  color: #F5BC25;
  position: absolute;
  display: inline-block;
  font-size: 2vw;
  letter-spacing: 0.4vw;
  line-height: 0vh;
  text-align: center;
  font-family: Mont;
  font-style: normal;
  font-weight: 500;
  line-height: 161.4%;
  letter-spacing: 0.2vw;
}

.emailid-contaiiner {
  position: relative;
  display: block;
  width: fit-content;
  height: fit-content;
  line-height: 1vw;
  margin-top: 0.7vw;
  text-align: left;
  color: white;
  left: 5vw;
  font-family: Mont;
}

.email-id {
  font-size: 1.4vw;
  text-decoration: none;
  color: white;
}

.emailid-container a {
  letter-spacing: 0.4vw;
  line-height: 0vh;
  text-align: center;
  font-family: Mont;
  font-style: normal;
  font-weight: 500;
  line-height: 161.4%;
  letter-spacing: 0vw;
  color: white;
  text-decoration: none;
}

.form-container {
  position: relative;
  width: 30vw;
  height: 35vw;
  background: linear-gradient(#464646, #464646) padding-box,
    linear-gradient(145deg, transparent 35%, #F5BC25, #F5BC25) border-box;
  border: 2px solid transparent;
  padding: 2vw 1.5vw;
  font-family: Mont;
  color: white;
  display: flex;
  flex-direction: column;
  gap: 20vw;
  box-sizing: border-box;
  border-radius: 0.7vw;
  flex-direction: row;
  margin-left: 10vw;
  margin-top: 0.8vw;
  z-index: 2;
}

.msgcontainer {
  position: absolute;
  margin-top: 0vw;
  left: 0rem;
  width: 30vw;
  height: 3vw;
  text-align: center;
  align-items: center;
  justify-content: center;

}

.msgcontainer h1 {
  font-size: 2.3vw;
  letter-spacing: 0.2vw;
  font-weight: 0;
  font-family: staat;
  font-weight: 50;
}

.form-container button:active {
  scale: 0.95;
}

.form-container .form {
  display: flex;
  flex-direction: column;
  gap: 1.5vw;
}

.form-container .form-group {
  display: flex;
  flex-direction: column;
  gap: 2px;

  height: fit-content;
}

.form-container .form-group label {
  position: relative;
  display: block;
  margin-top: 0vw;
  color: #ffffff;
  font-weight: 400;
  font-size: 1vw;
  margin-top: 0;
}

.form-group:nth-child(1) {
  position: relative;
  margin-top: 4vw;
  font-size: 1vw;
  width: 88%;
}

.form-group:nth-child(2) {
  position: relative;
  margin-top: 1vw;
  font-size: 1vw;
  width: 88%;
}

.form-group:nth-child(3) {
  position: relative;
  margin-top: 1vw;
  font-size: 2vw;
  width: 88%;
}

.form-container .form-group input {
  position: relative;
  width: 110%;
  padding: 0.5vw 0.5vw;
  border-radius: 0.6vw;
  color: #fff;
  font-family: inherit;
  margin-top: 0.01vw;
  background-color: transparent;
  border: 0.2vw solid #717171;
  font-size: 1vw;
  font-weight: 600;
  margin-top: 0.2vw;
}

.form-container .form-group textarea {
  position: relative;
  width: 110%;
  padding: 0.5vw 0.5vw;
  border-radius: 0.6vw;
  resize: none;
  color: #fff;
  height: 6vw;
  margin-top: 0.5vw;
  border: 0.2vw solid #717171;
  background-color: transparent;
  font-family: inherit;
  font-size: 1.1vw;
  line-height: 1.4vw;
}

.form-container .form-group input::placeholder {
  opacity: 0.5;
}

.form-container .form-group input:focus {
  outline: none;
  border-color: #F5BC25;
}

.form-container .form-group textarea:focus {
  outline: none;
  border-color: #F5BC25;
}

.form-container .form-submit-btn {
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  align-self: flex-start;
  font-family: inherit;
  color: #ffffff;
  font-weight: 500;
  width: 11vw;
  height: 3.2vw;
  background-color: #717171;
  border: 0.01vw solid #ffffff;
  padding: 0.7vw 0.5vw;
  font-size: 1.3vw;
  margin-top: 0;
  cursor: pointer;
  border-radius: 0.7vw;
  letter-spacing: 0.01vw;
}

.form-container .form-submit-btn:hover {
  background-color: #fff;
  border-color: #caac00;
  color: black;
}

.lastrectangle {
  position: absolute;
  height: 100%;
  width: 14vw;
  left: 86vw;
  top: 0;
  background-color: #caac00;
  z-index: 0;
}


@media screen and (min-width:0px) and (max-width:767px) {
  .ContactPageContainer {
    margin-top: 10vw;
    height: 90vh;
    flex-direction: row;
  }

  .contact-us-text {
    font-size: 7.5vw;
  }

  .content-container {
    margin-top: 3vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .information-container {
    order: 2;
    margin-top: 10vw;
  }

  .LocationContainer {
    margin-top: 8vw;
    padding-left: 3vw;
    display: block;
  }

  .location-icon {
    margin-top: 2vw;
    height: 6vw;
    width: 6vw;
  }

  .location-icon svg {
    height: 6vw;
    width: 6vw;
  }

  .location-text-container {
    position: absolute;
    width: 23vw;
    height: 9vw;
    margin-top: 2vw;
  }

  .location-text {
    height: 4vw;
  }

  .location-text a {
    font-size: 4vw;
  }

  .location-subtext {
    width: 57vw;
    left: 50%;
    transform: translateX(-50%);
  }

  .location-subtext h1 a {
    font-size: 2.2vw;
  }

  .call-container {
    margin-top: 0vw;
    padding-left: 3vw;
    display: block;
    margin-top: 7vw;
  }

  .phone-icon {
    margin-top: 2vw;
    height: 6vw;
    width: 6vw;
  }

  .phone-icon svg {
    height: 6vw;
    width: 6vw;
  }

  .call-us-container {
    position: absolute;
    width: 27vw;
    height: 10vw;
    margin-top: 2vw;
  }

  .call-us-text {
    font-size: 4vw;
  }

  .phone-number-container {
    width: 50vw;
    text-align: left;
    left: 0;
  }

  .phone-number-container h1 {
    font-size: 2.7vw;
    text-align: left;

  }

  .EmailContainer {
    margin-top: 7vw;
  }

  .email-icon {
    margin-top: 2vw;
    height: 6vw;
    width: 6vw;
  }

  .email-icon svg {
    height: 6vw;
    width: 6vw;
  }

  .email-text-container {
    position: absolute;
    width: 27vw;
    height: 9vw;
    margin-top: 2vw;
  }

  .email-text {
    font-size: 4vw;
  }

  .emailid-contaiiner {
    width: 50vw;
    text-align: left;
    left: 0;
  }

  .email-id {
    font-size: 2.7vw;
  }

  .emailid-container a {
    letter-spacing: 0.4vw;
    line-height: 0vh;
    text-align: center;
    font-family: Mont;
    font-style: normal;
    font-weight: 500;
    line-height: 161.4%;
    letter-spacing: 0vw;
  }

  .form-container {
    position: relative;
    order: 1;
    width: 52vw;
    height: 55vw;
    background: linear-gradient(#464646, #464646) padding-box,
      linear-gradient(145deg, transparent 35%, #F5BC25, #F5BC25) border-box;
    border: 2px solid transparent;
    padding: 2vw 1.5vw;
    font-family: Mont;
    color: white;
    display: flex;
    flex-direction: column;
    gap: 20vw;
    box-sizing: border-box;
    border-radius: 0.7vw;
    flex-direction: row;
    margin-left: 0vw;
    margin-top: 9vw;
    z-index: 2;
    display: flex;
    justify-content: center;
  }

  .msgcontainer {
    position: absolute;
    margin-top: 0vw;
    left: 0rem;
    width: 100%;
    height: 3vw;
    text-align: center;
    align-items: center;
    justify-content: center;
    left: 50%;
    transform: translateX(-50%);
  }

  .msgcontainer h1 {
    font-size: 4.7vw;
    letter-spacing: 0.2vw;
    font-weight: 0;
    font-family: staat;
    font-weight: 50;
  }

  .form-container button:active {
    scale: 0.95;
  }

  .form-container .form {
    display: flex;
    flex-direction: column;
    gap: 1.5vw;
  }

  .form-container .form-group {
    display: flex;
    flex-direction: column;
    gap: 2px;

    height: fit-content;
  }

  .form-container .form-group label {
    position: relative;
    display: block;
    margin-top: 0vw;
    color: #ffffff;
    font-weight: 400;
    font-size: 1.7vw;
    margin-top: 0;
  }

  .form-group:nth-child(1) {
    position: relative;
    margin-top: 12vw;
    font-size: 4vw;
    width: 88%;
  }

  .form-group:nth-child(2) {
    position: relative;
    margin-top: 1vw;
    font-size: 1vw;
    width: 88%;
  }

  .form-group:nth-child(3) {
    position: relative;
    margin-top: 1vw;
    font-size: 2vw;
    width: 88%;
  }

  .form-container .form-group input {
    position: relative;
    width: 110%;
    padding: 0.5vw 0.5vw;
    border-radius: 0.6vw;
    color: #fff;
    font-family: inherit;
    margin-top: 0.01vw;
    background-color: transparent;
    border: 0.2vw solid #717171;
    font-size: 1.5vw;
    font-weight: 600;
    margin-top: 0.2vw;
  }

  .form-container .form-group textarea {
    position: relative;
    width: 110%;
    padding: 0.5vw 0.5vw;
    border-radius: 0.6vw;
    resize: none;
    color: #fff;
    height: 8vw;
    margin-top: 0.5vw;
    border: 0.2vw solid #717171;
    background-color: transparent;
    font-family: inherit;
    font-size: 1.5vw;
    line-height: 1.4vw;
  }

  .form-container .form-group input::placeholder {
    opacity: 0.5;
  }

  .form-container .form-group input:focus {
    outline: none;
    border-color: #F5BC25;
  }

  .form-container .form-group textarea:focus {
    outline: none;
    border-color: #F5BC25;
  }

  .form-container .form-submit-btn {
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    align-self: flex-start;
    font-family: inherit;
    color: #ffffff;
    font-weight: 500;
    width: 11vw;
    height: 4vw;
    background-color: #717171;
    border: 0.01vw solid #ffffff;
    padding: 0.7vw 0.7vw;
    font-size: 1.7vw;
    margin-top: 3vw;
    cursor: pointer;
    border-radius: 0.7vw;
    letter-spacing: 0.01vw;
  }

  .form-container .form-submit-btn:hover {
    background-color: #fff;
    border-color: #caac00;
    color: black;
  }

  .lastrectangle {
    position: absolute;
    height: 100%;
    width: 14vw;
    left: 86vw;
    top: 0;
    background-color: #caac00;
    z-index: 0;
  }
}