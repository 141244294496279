.pagefourcontainer {
    position: relative;
    width: 100vw;
    height: 46.7vw;
    overflow: hidden;
}

.imgcontainer {
    position: absolute;
    height: 46.7vw;
    width: 100vw;
    top: 0;
    z-index: -1;
}

.pagefourbg {
    position: absolute;
    top: 0rem;
    left: -1.5vw;
    object-fit: fill;
    width: 110%;
    height: 100%;
}

.titlestudio {
    position: absolute;
    text-align: center;
    align-items: center;
    top: 4%;
    left: 50%;
    transform: translateX(-50%);
    color: #000;
    font-family: baloo;
    font-size: 3.6vw;
    font-style: normal;
    font-weight: 1000;
    line-height: 161.4%;
    z-index: 5;
}

.row-one {
    position: relative;
    margin-top: 7.5vw;
    width: 100vw;
    height: 16.5vw;

    z-index: 2;
}

.studiorow1 {
    position: absolute;
    border-radius: 4.5rem;
    left: 0rem;
    top: 0rem;
    width: 20.06vw;
    height: 16.5vw;
    z-index: 4;
    transform: translateX(-100%);
    transition: opacity 1s ease-in-out;

}

.studiorow1.animate,
.studiorow2.animate,
.studiorow3.animate {
    animation: slideleft 1.5s forwards;
}

@keyframes slideleft {
    to {
        transform: translateX(0);
    }
}

.studiorow1 img {
    border-radius: 3.9vw;
    position: relative;
    top: 0rem;
    left: -6vw;
    width: 26vw;
    height: 16.5vw;
}

.recordingstudio {
    border-radius: 3.95vw;
    position: absolute;
    width: 25vw;
    height: 16.48vw;
    top: 0rem;
    left: -5vw;
    text-align: center;
    justify-content: center;
    align-items: center;
    font-family: staat;
    transition: 0.3s all ease-in-out;
    letter-spacing: 0.2vw;

}

.recordingstudio h1 {
    position: relative;
    color: transparent;
    width: 22.5vw;
    height: 16.3vw;
    padding-top: 7vw;
    font-size: 2vw;
    border-radius: 4vw;
    left: 2.45vw;
    top: 0.1vw;
    z-index: 8;

}

.studiorow1 :hover {
    color: white;
}

.studiorow1:hover img {
    filter: blur(3.5px);
}

/* pencil*/
.studiorow2 {
    position: absolute;
    top: 0rem;
    left: 22vw;
    width: 26vw;
    height: 16.5vw;
    z-index: 4;
    border-radius: 4vw;
    transition: 0.2s all ease-in-out;
    transform: translateX(-100%);
    transition: opacity 1s ease-in-out;
}

.studiorow2 img {
    border-radius: 4vw;
    position: absolute;
    margin: 0;
    left: 0rem;
    top: 0rem;
    width: 26vw;
    height: 16.5vw;
    transition: filter 0.5s ease-in-out;
}

.pencilmike {
    position: absolute;
    border-radius: 4.1vw;
    width: 26vw;
    height: 16.5vw;
    top: 0rem;
    text-align: center;
    justify-content: center;
    align-items: center;
}

.pencilmike h1 {
    position: absolute;
    color: transparent;
    top: 0;
    width: 26vw;
    height: 16.5vw;
    padding-top: 6vw;
    font-size: 2vw;
    font-family: staat;
    border-radius: 4vw;
    z-index: 8;
    transition: 0.3s all ease-in-out;
    letter-spacing: 0.2vw;
}

.studiorow2 :hover {
    color: white;

}

.studiorow2:hover img {
    filter: blur(3.5px);
}

.studiorow3 {
    position: absolute;
    top: 0;
    width: 26vw;
    height: 16.5vw;
    left: 50vw;
    z-index: 4;
    border-radius: 4vw;
    transform: translateX(-100%);
    transition: opacity 1s ease-in-out;
}

.studiorow3 img {
    border-radius: 4vw;
    position: absolute;
    top: 0rem;
    left: 0rem;
    margin: 0ch;
    width: 26vw;
    height: 16.5vw;
}

.mixertext {
    position: absolute;
    border-radius: 4.1vw;
    width: 26vw;
    height: 16.5vw;
    text-align: center;
    justify-content: center;
    align-items: center;
}

.mixertext h1 {
    position: relative;
    color: transparent;
    left: 0.1vw;
    width: 25.8vw;
    height: 16.5vw;
    padding-top: 7.3vw;
    font-size: 2vw;
    font-family: staat;
    border-radius: 4vw;
    top: 0rem;
    z-index: 8;
    transition: 0.3s all ease-in-out;
    letter-spacing: 0.2vw;
}

.studiorow3 :hover {
    color: white;

}

.studiorow3:hover img {
    filter: blur(4px);
}

.studiorow4 {
    position: absolute;
    left: 78vw;
    top: 0rem;
    width: 26vw;
    height: 16.5vw;
    z-index: 4;
    border-radius: 4.1vw;
    transform: translateX(-100%);
    transition: opacity 1s ease-in-out;
}

.studiorow4.animate {
    animation: slideleft 1.5s forwards;
}

@keyframes slideleft {
    to {
        transform: translateX(0);
    }
}

.studiorow4 img {
    border-radius: 4.1vw;
    position: absolute;
    margin: 0;
    width: 26vw;
    height: 16.5vw;
}

.samemike {
    z-index: 100;
    border-radius: 4vw;
    position: absolute;
    width: 26.5vw;
    height: 16.5vw;
    top: 0.2vw;
    left: 0.1vw;
    text-align: center;
    justify-content: center;
    align-items: center;
}

.samemike h1 {
    position: absolute;
    color: transparent;
    width: 26.5vw;
    height: 16.5vw;
    padding-top: 7.3vw;
    font-size: 2vw;
    font-family: staat;
    border-radius: 3.9vw;
    top: 0rem;
    z-index: 8;
    transition: 0.3s all ease-in-out;
    letter-spacing: 0.2vw;
}

.studiorow4 :hover {
    color: white;

}

.studiorow4:hover img {
    filter: blur(4px);
}

.row-two {
    position: relative;
    margin-top: 3vw;
    width: 100vw;
    height: 17vw;
    z-index: 2;
}

.studiorow5 {
    position: absolute;
    border-radius: 3.8vw;
    top: 0;
    left: -14.5vw;
    width: 24vw;
    height: 17vw;
    z-index: 4;
    transform: translateX(100%);
    transition: opacity 1s ease-in-out;
}

.studiorow5.animate,
.studiorow6.animate,
.studiorow7.animate,
.studiorow8.animate,
.studiorow9.animate {
    animation: slideInRight 1.5s forwards;
}

@keyframes slideInRight {
    to {
        transform: translateX(0%);
    }
}

.studiorow5 img {
    border-radius: 4vw;
    position: absolute;
    margin: 0;
    width: 24vw;
    height: 17vw;
}

.studiorow5:hover img {
    filter: blur(3.5px);
}

.studiorow6 {
    position: absolute;
    top: 0;
    width: 25vw;
    height: 17vw;
    z-index: 4;
    left: 11vw;
    border-radius: 4.1rem;
    transform: translateX(100%);
    transition: opacity 1s ease-in-out;
}

.studiorow6 img {
    border-radius: 4.1vw;
    position: absolute;
    margin: 0;
    width: 25vw;
    height: 17vw;
}

.liveroomtext {
    position: absolute;
    width: 25vw;
    height: 17vw;
    top: 0rem;
    left: 0rem;
    border-radius: 4vw;
    text-align: center;
    justify-content: center;
    align-items: center;
}

.liveroomtext h1 {
    position: relative;
    color: transparent;
    width: 24.8vw;
    left: 0.1vw;
    height: 16.8vw;
    padding-top: 7vw;
    font-size: 2.2vw;
    font-family: staat;
    border-radius: 3.9vw;
    top: 0.1vw;
    z-index: 8;
    transition: 0.3s all ease-in-out;
    letter-spacing: 0.2vw;
}

.studiorow6 :hover {
    color: white;

}

.studiorow6:hover img {
    filter: blur(3.5px);
}

.studiorow7 {
    position: absolute;
    top: 0;
    width: 25vw;
    height: 17vw;
    left: 38vw;
    z-index: 4;
    border-radius: 3.8vw;
    transform: translateX(100%);
    transition: opacity 1s ease-in-out;
}

.studiorow7 img {
    border-radius: 3.8vw;
    position: absolute;
    margin: 0;
    width: 25vw;
    height: 17vw;
}

.allmikes {
    z-index: 100;
    position: absolute;
    border-radius: 3.6vw;
    width: 25vw;
    height: 17vw;
    text-align: center;
    justify-content: center;
    align-items: center;
}

.allmikes h1 {
    position: relative;
    color: transparent;
    height: 16.7vw;
    width: 24.7vw;
    left: 0.1vw;
    padding-top: 7vw;
    font-size: 2.2vw;
    font-family: staat;
    border-radius: 3.6vw;
    top: 0rem;
    z-index: 8;
    transition: 0.3s all ease-in-out;
    letter-spacing: 0.2vw;
}

.studiorow7 :hover {
    color: white;
}

.studiorow7:hover img {
    filter: blur(3.5px);
}

.studiorow8 {
    position: absolute;
    top: 0;
    width: 25vw;
    height: 17vw;
    left: 65vw;
    z-index: 4;
    border-radius: 3.8vw;
    transform: translateX(100%);
    transition: opacity 1s ease-in-out;
}

@keyframes slideInRight {
    to {
        transform: translateX(0%);
    }
}

.studiorow8 img {
    border-radius: 4vw;
    position: relative;
    width: 25vw;
    height: 17vw;
    margin: 0%;
}

.loungeroom {
    border-radius: 4vw;
    position: absolute;
    width: 25vw;
    top: 0rem;
    height: 17vw;
    text-align: center;
    justify-content: center;
    align-items: center;
}

.loungeroom h1 {
    position: absolute;
    width: 24.9vw;
    top: 0rem;
    height: 17vw;
    color: transparent;
    border-radius: 4vw;
    padding-top: 7vw;
    font-size: 2.2vw;
    font-family: staat;
    top: 0rem;
    z-index: 8;
    transition: 0.3s all ease-in-out;
    letter-spacing: 0.2vw;
}

.studiorow8 :hover {
    color: white;
}

.studiorow8:hover img {
    filter: blur(3.5px);
}

.studiorow9 {
    position: absolute;
    top: 0;
    width: 25vw;
    height: 17vw;
    left: 92vw;
    z-index: 4;
    border-radius: 4vw;
    transform: translateX(100%);
    transition: opacity 1s ease-in-out;
}

@keyframes slideInRight {
    to {
        transform: translateX(0%);
    }
}

.studiorow9 img {
    border-radius: 4vw;
    position: absolute;
    margin: 0;
    width: 14vw;
    height: 17vw;
}

.studiorow9:hover img {
    filter: blur(3.5px);
}


@media screen and (max-width:767px) {
    .pagefourcontainer {
        display: none;
    }
}