.pagereviewcontainer
{
    position: relative;
    width: 100vw;
    height: fit-content;
    margin: 0;
    padding: 0;
}
.security-title
{
    position: absolute;
    font-family: morn;
    font-size: 4.2vw;   
    top: 2vw;
    left: 20vw;
    color: white;
}
.security-line
{
    position: absolute;
    left: 20vw;
    top: 7.5vw;
    background-color: #F5BC25;
    height: 0.2vw;
    width: 75vw;
}
.security-input-fields
{
    position: absolute;
    width: 75vw;
    height: 30vw;
    left: 20vw;
    top: 10vw;
}
#security-current-password-input-label
{
    position: absolute;
    color: white;
    font-family: Avalon;
    font-size: 2.5vw;
    margin-top: 1vw;
    top: 0;
    left: 0;
}
#security-current-password-input
{
    position: absolute;
    color: black;
    font-family: Avalon;
    padding: 0.3vw 0.3vw;
    font-size: 1.9vw;
    width: 40vw;
    top: 4.5vw;
} 
#security-new-pass1-input-label
{
    position: absolute;
    color: white;
    font-family: Avalon;
    font-size: 2.5vw;
    margin-top: 9vw;
    top: 0rem;
    left: 0vw;
}
#security-new-pass1-input
{
    position: absolute;
    color: black;
    font-family: Avalon;
    padding: 0.3vw 0.3vw;
    font-size: 1.9vw;
    width: 40vw;
    top: 12vw;
    left: 0;
} 
#security-new-pass2-label
{
    position: absolute;
    color: white;
    font-family: Avalon;
    font-size: 2.5vw;
    top: 17vw;
    left: 0;
}
#security-new-pass2
{
    position: absolute;
    color: black;
    font-family: Avalon;
    padding: 0.3vw 0.3vw;
    font-size: 1.9vw;
    width: 40vw;
    top: 20vw;
} 
#security-submit-button
{
    position: absolute;
    width: 8vw;
    height: 3vw;
    font-family: Morn;
    font-size: 2vw;
    top: 38vw;
    left: 52vw;
    color: white;
    background-color: #252525;
    border: none;
    cursor: pointer;
    transition: 0.3s all ease-in-out;
    border-radius: 0.2vw;
}
#security-submit-button:hover
{
    background-color: #F5BC25;
    color: black;
}
