.servicecardcontainer {
  width: 21.5vw;
  height: 27.2vw;
  object-fit: cover;
  border-radius: 1.2vw;
  box-shadow: 0.3vw 0.3vw 0.15vw 0px rgba(0, 0, 0, 0.70);
}


.service1 {
  width: 22vw;
  height: 27.5vw;
  object-fit: cover;
  position: relative;
  margin: 0;
  top: 0rem;
  left: 0rem;
  cursor: pointer;
}

.servicetitle {
  position: relative;
  color: #F5BC25;
  text-align: center;
  font-family: ava;
  font-size: 0.7vw;
  font-style: normal;
  font-weight: 400;
  line-height: 161.4%;
  top: -4vw;
  left: 0rem;
  z-index: 1;
  background-color: #222;
  width: 15.7vw;
  height: 1.8vw;
}

.servicetitle h1 {
  position: absolute;
  top: 15%;
  text-align: center;
  right: 4%;

}

.knowmore {
  position: relative;
  border-radius: 1.2vw;
  background: #F5BC25;
  width: 4.6vw;
  height: 1.8vw;
  top: -5.8vw;
  left: 16.5vw;
  z-index: 2;
  cursor: pointer;
}

.knowmore h1 {
  position: absolute;
  color: #000;
  width: 4.6vw;
  height: 1.8vw;
  top: 10%;
  text-align: center;
  font-family: bayon;
  font-size: 0.95vw;
  font-style: normal;
  font-weight: 400;
  line-height: 161.4%;
}

@media screen and (min-width:0px) and (max-width:767px) {
  .servicecardcontainer {
    width: 32vw;
    height: 24.5vh;
    border-radius: 3vw;
    box-shadow: 0.3vw 1vw 0.8vw 0px rgba(0, 0, 0, 0.70);
    /* background-color: red; */
  }

  .service1 {
    width: 100%;
    height: 102%;
    border-radius: 3vw;
  }

  .servicetitle {
    font-size: 1.1vw;
    top: -9vw;
    left: 0rem;
    width: 23vw;
    height: 3.7vw;
  }

  .servicetitle h1 {
    top: 50%;
    transform: translateY(-50%);
    right: 4%;

  }

  .knowmore {
    border-radius: 1.2vw;
    background: #F5BC25;
    width: 7vw;
    height: 2.5vw;
    top: -12vw;
    left: 23.8vw;
    z-index: 2;
  }

  .knowmore h1 {
    width: 6vw;
    height: 1.8vw;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 1.2vw;
  }
}