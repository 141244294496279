.gallerycontainer {
  position: relative;
  width: 100vw;
  height: 170vh;
  margin: 0;
  margin-top: 3rem;
  padding: 0;
  display: block;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;
}


.gallery1rect {
  position: absolute;
  width: 0.2rem;
  height: 13rem;
  background-color: #FFF;
  left: 22rem;
  top: 5rem;

}

.gallery2rect {
  position: absolute;
  width: 14rem;
  height: 0.2rem;
  top: 5rem;
  left: 22rem;
  background-color: #FFF;
}

.circlegallery {
  position: absolute;
  z-index: 3;
  top: 7.5rem;
  left: 79rem;
  fill: #F5BC25;
  backdrop-filter: blur(5px);
}

.melvideo {
  position: absolute;
  filter: drop-shadow(7px 7px 4px rgba(0, 0, 0, 0.52));
}

.galleryherotext {
  position: relative;
  width: 80rem;
  height: 20rem;
  top: 35rem;
  display: block;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  left: 50%;
  transform: translateX(-50%);
}

.gallhero {
  z-index: -1;
  position: absolute;
  text-align: center;
  font-family: monda;
  font-size: 17.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1;
  letter-spacing: 0.2625rem;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.20) 0%, rgba(255, 255, 255, -1) 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  opacity: 0;
  transform: scale(0.5) translateY(100%);
  transition: opacity 1s ease-in-out, transform 1.5s ease-in-out;
}

.gallhero span {
  padding: 0;
  line-height: 0.5;

}

.gallhero.animate {
  animation: fadeInAndScale 1s ease-in-out forwards, slideInFromBottom 1.5s ease-in-out 1s forwards;
}

@keyframes fadeInAndScale {
  to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes slideInFromBottom {
  to {
    transform: translateY(0);
  }
}

.carousel {
  position: absolute;
  margin: 0;
  padding: 0;
  top: 51rem;
  width: 95vw;
  height: 22rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.carousel img {
  margin: 0;
}

.gall1 {

  top: 0rem;
  width: 19rem;
  height: 22rem;
  left: 41.2rem;
  border-radius: 1.2rem;
  object-fit: cover;

}

.gall2 {

  left: 60.9rem;
  width: 16rem;
  height: 19rem;
  border-radius: 1.2rem;
  object-fit: cover;
}

.gall3 {

  left: 24.5rem;
  width: 16rem;
  height: 19rem;
  border-radius: 1.2rem;
  object-fit: cover;
}

.gall4 {
  position: absolute;
  left: 11rem;
  width: 13rem;
  height: 16rem;
  border-radius: 1.2rem;
  object-fit: cover;
}

.gall6 {
  position: absolute;
  left: 77.4rem;
  width: 13rem;
  height: 16rem;
  border-radius: 1.2rem;
  object-fit: cover;
}

.gall5 {
  position: absolute;
  left: 1.5rem;
  width: 9rem;
  height: 12rem;
  border-radius: 1.2rem;
  object-fit: cover;
}

.gall7 {
  position: absolute;
  left: 91rem;
  width: 9rem;
  height: 12rem;
  border-radius: 1.2rem;
  object-fit: cover;
}

.video-container {
  position: relative;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 42rem;
  height: 23rem;
}

.carousel-container {
  position: relative;
  width: 100vw;
  height: 25vw;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: blue; */
}

.soundcloud-iframe {
  background: black !important;
}

.sound-cloud-widget {
  width: 80vw;
  position: relative;
  height: 14vw;
  overflow: hidden;
  left: 50%;
  transform: translateX(-50%);
}

@media screen and (min-width: 0px) and (max-width: 767px) {
  .gallerycontainer {
    height: fit-content;
    overflow: hidden;
  }

  .video-container {
    top: 34vw;
    height: 50vw;
    width: 80vw;
    z-index: 2;
  }

  .gallery1rect {
    width: 0.5vw;
    height: 20vw;
    left: 0rem;
    top: 0rem;
  }

  .gallery2rect {
    width: 20vw;
    height: 0.5vw;
    left: 0rem;
    top: 0rem;
  }

  .circlegallery {
    top: 1vw;
    left: 70vw;
    height: 7vw;
    width: 7vw;
  }

  .circlegallery svg {
    height: 7vw;
    width: 7vw;
  }

  .melvideo {
    position: relative;
    width: 70vw;
    height: 42vw;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }


  .galleryherotext {
    width: 98vw;
    height: 30vw;
    top: 0rem;

  }

  .gallhero {
    font-size: 20vw;
    letter-spacing: 0.2625rem;
  }

  .carousel-container {
    margin: 0;
    padding: 0;
    top: -12vw;
    position: relative;
    width: 88vw;
    height: 30vw;
    left: 50%;
    transform: translateX(-50%);
  }

  .carousel-item-1,
  .carousel-item-2,
  .carousel-item-3,
  .carousel-item-4,
  .carousel-item-5,
  .carousel-item-6,
  .carousel-item-7 {
    height: 25vw;
    width: 15vw;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 1.2vw;
    top: 50%;
  }

  .gall1,
  .gall2,
  .gall3,
  .gall4,
  .gall5,
  .gall6,
  .gall7 {
    height: 18vw;
    width: 12vw;
    border-radius: 1.2vw;
    position: relative;
    left: 62.5%;
    top: 50%;
    transform: translate(-50%, -50%);

  }

  .center {
    z-index: 1;
    border-radius: 1.2vw;
    height: 25vw;
    top: 1vw;
    transition: all 0.3s ease-in-out;
  }

  .carousel-container .center img {
    border-radius: 1.2vw;
    position: relative;
    top: 0%;
    transform: translateY(-50%);
    height: 25vw;
    width: 17vw;
    left: 50%;
    transform: translateX(-50%);
  }

  .side {
    z-index: 1;
    border-radius: 1.2vw;
    height: 25vw;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s ease-in-out;
  }

  .carousel-container .side img {
    border-radius: 1.2vw;
    position: relative;
    top: 6%;
    height: 22vw;
    width: 15vw;
    left: 50%;
    transform: translateX(-50%);
  }

  .carousel-container .right-corner img {
    left: 35%;
  }

  .sound-cloud-widget {
    width: 90vw;
    position: relative;
    height: 34vw;
    overflow: hidden;
    left: 50%;
    transform: translateX(-50%);
  }
}

@media screen and (min-width: 768px) {
  .gallerycontainer {
    height: fit-content;
    overflow: hidden;
  }

  .video-container {
    top: 22vw;
    width: 75vw;
    height: 38vw;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
  }

  .gallery1rect {
    width: 0.2vw;
    height: 12vw;
    left: 0rem;
    top: 0rem;
  }

  .gallery2rect {
    width: 12vw;
    height: 0.2vw;
    left: 0rem;
    top: 0rem;
  }

  .circlegallery {
    top: 0vw;
    left: 70vw;
    height: 4.5vw;
    width: 4.5vw;
  }

  .circlegallery svg {
    height: 4.5vw;
    width: 4.5vw;
  }

  .melvideo {

    width: 70vw;
    height: 35vw;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .galleryherotext {
    width: 100vw;
    height: 20vw;
    top: -3.5vw;
    z-index: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .gallhero {
    position: absolute;
    font-size: 19vw;
    letter-spacing: 0.2625rem;
  }

  .gallhero span {

    height: 10vw;
    margin: 0;
    padding: 0;
  }

  .carousel-container {
    margin: 0;
    padding: 0;
    top: -7vw;
    position: relative;
    width: 92vw;
    height: 30vw;
    left: 50%;
    transform: translateX(-50%);
  }

  .carousel-item-1,
  .carousel-item-2,
  .carousel-item-3,
  .carousel-item-4,
  .carousel-item-5,
  .carousel-item-6,
  .carousel-item-7 {
    height: 25vw;
    width: 15vw;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 1.2vw;
    top: 50%;
  }

  .gall1,
  .gall2,
  .gall3,
  .gall4,
  .gall5,
  .gall6,
  .gall7 {
    height: 18vw;
    width: 12vw;
    border-radius: 1.2vw;
    position: relative;
    left: 64%;
    top: 50%;
    transform: translate(-50%, -50%);

  }

  .center {
    z-index: 1;
    border-radius: 1.2vw;
    height: 25vw;
    top: 1vw;
    transition: all 0.3s ease-in-out;
  }

  .carousel-container .center img {
    border-radius: 1.2vw;
    position: relative;
    top: 0%;
    transform: translateY(-50%);
    height: 25vw;
    width: 17vw;
    left: 50%;
    transform: translateX(-50%);
  }

  .side {
    z-index: 1;
    border-radius: 1.2vw;
    height: 25vw;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s ease-in-out;
  }

  .carousel-container .side img {
    border-radius: 1.2vw;
    position: relative;
    top: 6%;
    height: 22vw;
    width: 15vw;
    left: 50%;
    transform: translateX(-50%);
  }

  .carousel-container .right-corner img {
    left: 35%;
  }

}

.pageReviewcontainer {
  position: relative;
  margin-top: 0rem;
  width: 100vw;
  height: fit-content;
  justify-content: center;
}


.testi {
  position: relative;
  top: 3rem;
  height: fit-content;
  text-align: center;
  color: white;
  font-family: Maven;
  font-weight: 800;
  font-size: 7vw;
  letter-spacing: 0.2rem;
}

@media screen and (min-width: 0px) and (max-width: 768px) {
  .pageReviewcontainer {
    margin-top: 0rem;
    height: fit-content;
  }

  .testi {
    top: 1vw;
    font-size: 5vw;
    letter-spacing: 0.2rem;
  }
}