.footer-containter {
    position: relative;
    background-color: black;
    width: 100%;
    height: 2vw;
    color: white;
    font-family: Mont;
    font-size: 1vw;
}

.footer-containter p {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.footer-containter a {
    color: white;
    text-decoration: none;
    transition: 0.1s ease-in-out all;
}

.footer-containter a:hover {
    color: #F5BC25;
}

.footer-containter span {
    color: #F5BC25;
}

@media screen and (min-width:0px) and (max-width:767px) {
    .footer-containter {
        position: relative;
        background-color: black;
        width: 100%;
        height: 6vw;
        color: white;
        font-family: Mont;
        font-size: 2vw;
        text-align: center;
    }

    .footer-containter a {
        font-size: 2vw;

    }
}