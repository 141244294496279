.pagefivecontainer{
  position:relative;
  width: 100vw;
  height:100vh;
  margin-top: 2vw;
  padding: 0;
  overflow-x: hidden;
}
*{
  margin:0;
  padding:0;
}
.facilitycircle{
  position:relative;
  border-radius: 495px;
  border: 4px solid #F5BC25;
  width: 390px;
  height: 495px;
  transform: rotate(-90deg);
  flex-shrink: 0;
  top:-18rem;
  left:-18rem;
  z-index: -4;
}
.facilityherotext{
  position: relative;
  height:4rem;
  top:-29rem;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  transform: scale(1.3) translateY(-100%);
  transition: opacity 1s ease-in-out;
  }
  .facilityherotext.animate { 
    animation: fadeInAndScale 1s ease-in-out forwards, slideInFromTop 1s ease-in-out 1s forwards;
  }
    
    @keyframes fadeInAndScale {
        to {
            opacity: 1;
            transform: scale(1);
        }
    }
    
    @keyframes slideInFromTop {
        to {
            transform: translateY(0);
        }
    }
.facilityherotext h1{
  position: absolute;
  color: #FFF;
  font-family: staat;
  font-size:3.3rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  transform: scale(1.3) translateY(-100%);
  animation: fadeInAndScale 1s ease-in-out forwards, slideInFromTop 1s ease-in-out 1s forwards;
}
  
  @keyframes fadeInAndScale {
      to {
          opacity: 1;
          transform: scale(1);
      }
  }
  
  @keyframes slideInFromTop {
      to {
          transform: translateY(0);
      }
  }
.facilitysubcontainer{
  position:relative;
  display:flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  width:100vw;
  top:-26.5rem;
}
.facilitysub{
  width:48rem;
  position:absolute;
  height:4rem;
}
.facilitysubtext {
  color: #b0b0b0;
  position: relative;
  font-family: Morn;
  font-size: 1.35rem;
  font-style: normal;
  line-height: normal;
  }
  .all5images {
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100vw;
    height: 31rem;
  }
.all5images img{
  margin:0rem;
}
.fac1container {
  width: 22rem;
  height: 31.5rem;
  position: absolute;
  top: 0rem;
  left: 8%;
  display: flex;
  text-align: center;
  justify-content: center;
  object-fit: contain;
  border-radius: 2rem;
  color: transparent;
  cursor: pointer;
  transition: 0.3s all ease-in-out;
}
.fac1 {
  position: absolute;
  width: 22rem;
  height: 31.5rem;
  border-radius: 2rem;
  transform: translateX(-100%);
  transition: opacity 1s ease-in-out;
  }
  .fac1.animate { 
    animation: slideInLeft 2.8s forwards;
  }
  @keyframes slideInLeft {
    to {
        transform: translateX(0);
    }
  }

.fac1container:hover {
  transform: scale(1.1);
  z-index: 4;
  box-shadow: 3px 3px 1px 0px rgba(0, 0, 0, 0.70);
  color:#F5BC25;
}

.fac1container:hover h1 {
  background-color: #252323;
}


.fac1container  h1{
  border-radius: 1.5rem;
  top: 28.5rem;
  width:13rem;
  height:2.3rem;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  font-size: 1.3rem;
  letter-spacing: 0.1rem;
  font-family: Morn;
  position: absolute;
}
.fac1container .fac1subtext
{
  display: none;
}
.fac1container .fac1subtext.displayed
{
  background-color: #252323;
  border-radius: 1.2rem;
  font-family: Morn;
  width:50rem;
  height: 18rem;
  display: flex;
  margin: 2.5rem;
  padding: 1.5rem;
  margin-top: 6rem;
  color: white;
  z-index: 3;
  font-size: 1rem;
  text-align: center;
  line-height: 2rem;
}

.fac2container{
  width:22rem;
  height:31rem;
  position:absolute;
  top:0rem;
  left: 22%;
  object-fit: contain;
  display: flex;
  text-align: center;
  justify-content: center;
  border-radius: 2rem;
  z-index: 3;
  color: transparent;
  transition: 0.3s all ease-in-out;
  cursor: pointer;
} 
.fac2{
  position: absolute;
  width: 22rem;
  height: 31.5rem;
  border-radius: 2rem;
  transform: translateX(-100%);
  transition: opacity 1s ease-in-out;
  }
  .fac2.animate { 
    animation: slideInLeft 2.4s forwards;
  }
  @keyframes slideInLeft {
    to {
        transform: translateX(0);
    }
  }
.fac2container:hover {
  transform: scale(1.1);
  z-index: 6;
  box-shadow: 3px 3px 1px 0px rgba(0, 0, 0, 0.70);
  color:#F5BC25;
}

.fac2container:hover h1 {
  background-color: #252323;
}

.fac2container h1 {
  border-radius: 1.5rem;
  top: 28.5rem;
  width:13rem;
  height:2.3rem;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 4;
  font-size: 1.3rem;
  letter-spacing: 0.1rem;
  font-family: Morn;
  position: absolute;
  display: flex;
}
.fac2container .fac2subtext
{
  display: none;
}
.fac2container .fac2subtext.displayed2
{
  background-color: #252323;
  border-radius: 1.2rem;
  font-family: Morn;
  width:50rem;
  height: 22rem;
  display: flex;
  margin: 2.5rem;
  padding: 2rem;
  margin-top: 3rem;
  color: white;
  z-index: 3;
  font-size: 1rem;
  text-align: center;
  line-height: 2rem;
}

.fac3container{
  width:22rem;
  height:31rem;
  position:absolute;
  top:0rem;
  left: 36%;
  z-index:5;
  object-fit: contain;
  display: flex;
  text-align: center;
  justify-content: center;
  border-radius: 2rem;
  color: transparent;
  cursor: pointer;
  transition: 0.3s all ease-in-out;
} 
.fac3{
  position:absolute;
  width:22rem;
  height:31.5rem;
  border-radius: 2rem;
  transform: translateX(-100%);
  transition: opacity 1s ease-in-out;
  }
.fac3.animate { 
  animation: slideInLeft 1.9s forwards;
}
@keyframes slideInLeft {
  to {
      transform: translateX(0);
  }
}
.fac3container:hover {
  transform: scale(1.1);
  z-index: 8;
  box-shadow: 3px 3px 1px 0px rgba(0, 0, 0, 0.70);
  color:#F5BC25;
}
.fac3container:hover h1 {
  background-color: #252323;
}
.fac3container h1 {
  border-radius: 1.5rem;
  top: 28.5rem;
  width:15rem;
  height:2.3rem;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 6;
  font-size: 1.3rem;
  letter-spacing: 0.1rem;
  font-family: Morn;
  position: absolute;
  display: flex;
}
.fac3container .fac3subtext
{
  display: none;
}

.fac3container .fac3subtext.displayed3
{
  background-color: #252323;
  border-radius: 1.2rem;
  font-family: Morn;
  width:50rem;
  height: 22rem;
  display: flex;
  margin: 2rem;
  padding: 1rem;
  margin-top: 2.5rem;
  color: white;
  z-index: 3;
  font-size: 1.1rem;
  text-align: center;
  line-height: 2rem;
}

.fac4container{
  width:22rem;
  height:31rem;
  position:absolute;
  top:0rem;
  left: 52%;
  object-fit: contain;
  display: flex;
  z-index: 7;
  text-align: center;
  justify-content: center;
  border-radius: 2rem;
  color: transparent;
  cursor: pointer;
  transition: 0.3s all ease-in-out;
} 
.fac4{
  position:absolute;
  width:22rem;
  height:31.5rem;
  border-radius: 2rem;
  transform: translateX(-100%);
  transition: opacity 1s ease-in-out;
  }
.fac4.animate { 
  animation: slideInLeft 1.2s forwards;
}
@keyframes slideInLeft {
  to {
      transform: translateX(0);
  }
}
.fac4container:hover {
  transform: scale(1.1);
  z-index: 10;
  box-shadow: 3px 3px 1px 0px rgba(0, 0, 0, 0.70);
  color:#F5BC25;
}
.fac4container:hover h1 {
  background-color: #252323;
}
.fac4container h1 {
  border-radius: 1.5rem;
  top: 28.5rem;
  width:15rem;
  height:2.3rem;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 7;
  font-size: 1.3rem;
  letter-spacing: 0.1rem;
  font-family: Morn;
  position: absolute;
  display: flex;
} 
.fac4container .fac4subtext
{
  display: none;
}

.fac4container .fac4subtext.displayed4
{
  background-color: #252323;
  border-radius: 1.2rem;
  font-family: Morn;
  width:50rem;
  height: 22rem;
  display: flex;
  margin: 2rem;
  padding: 1rem;
  margin-top: 2.5rem;
  color: white;
  z-index: 3;
  font-size: 1.1rem;
  text-align: center;
  line-height: 2rem;
}
.fac5container{
  width:22rem;
  height:31rem;
  position:absolute;
  top:0rem;
  left: 68%;
  object-fit: contain;
  display: flex;
  z-index:9;
  text-align: center;
  justify-content: center;
  border-radius: 2rem;
  color: transparent;
  cursor: pointer;
  transition: 0.3s all ease-in-out;
} 
.fac5{
  position:absolute;
  width:22rem;
  height:31.5rem;
  border-radius: 2rem;
  transition: 0.3s all ease-in-out;
  transform: translateX(-100%);
  transition: opacity 1s ease-in-out;
  }
.fac5.animate { 
  animation: slideInLeft 0.7s forwards;
}
@keyframes slideInLeft {
  to {
      transform: translateX(0);
  }
}
.fac5container:hover {
  transform: scale(1.1);
  z-index: 10;
  box-shadow: 3px 3px 1px 0px rgba(0, 0, 0, 0.70);
  color:#F5BC25;
}
.fac5container:hover h1 {
  background-color: #252323;
}
.fac5container h1 {
  border-radius: 1.5rem;
  top: 28.5rem;
  width:13rem;
  height:2.3rem;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9;
  font-size: 1.3rem;
  letter-spacing: 0.1rem;
  font-family: Morn;
  position: absolute;
  display: flex;
}
.fac5container .fac5subtext
{
  display: none;
}

.fac5container .fac5subtext.displayed5
{
  background-color: #252323;
  border-radius: 1.2rem;
  font-family: Morn;
  width:50rem;
  height: 15rem;
  display: flex;
  margin: 2.5rem;
  padding: 1.5rem;
  padding-top: 4rem;
  margin-top: 5rem;
  color:white;
  z-index: 3;
  font-size: 1.5rem;
  text-align: center;
  line-height: 3rem;
}

.fac1container.active .fac1 {
  filter: blur(3px); 
  /* content: 'hello'; */
} 
.fac2container.active2 .fac2 {
  filter: blur(3px); 
}

.fac3container.active3 .fac3 {
  filter: blur(3px);
}

.fac4container.active4 .fac4 {
  filter: blur(3px);
}

.fac5container.active5 .fac5 {
  filter: blur(3px);
} 
@media screen and (min-width:767px) 
{
  .pagefivecontainer
  {
    height: 46vw;
  }
  .facilitycircle{
    border-radius: 40vw;
    width: 27vw;
    height: 32vw;
    top:-40%;
    left:-20%;
    z-index: -1;
  }
  .facilityherotext{
    position: absolute;
    height:4vw;
    top:4%;
    width: 100vw;
    }
  .facilityherotext h1{
    font-size:3.3vw;
  }
  .facilitysubcontainer{
    position:absolute;
    width:100vw;
    top:18%;
  }
  .facilitysub{
    width:50vw;
    height:4vw;
  }
  .facilitysubtext {
    color: #b0b0b0;
    position: relative;
    font-family: Morn;
    font-size: 1.4vw;
    font-style: normal;
    line-height: normal;
    }
    .all5images {
      top: 60%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100vw;
      height: 33vw;
    }
  .fac1container {
    width: 23vw;
    height: 32.8vw;
    top: 0rem;
    left: 8%;
    border-radius: 2vw;
  }
  .fac1 {

    width: 23vw;
    height: 32.8vw;
    border-radius: 2vw;
    }
  .fac1container  h1{
    border-radius: 1.5vw;
    top: 90%;
    width:13vw;
    height:2.3vw;
    font-size: 1.4vw;
  }
  .fac1container .fac1subtext.displayed
  {
  border-radius: 1.2vw;
  width:80%;
  height: fit-content;
  margin: 2vw;
  padding: 1.5vw;
  margin-top: 6vw;
  font-size: 1.05vw;
  line-height: 2vw;
  }
  
  .fac2container{
    width: 23vw;
    height: 32.8vw;
    top: 0rem;
    left: 22%;
    border-radius: 2vw;
  } 
  .fac2{
    width: 23vw;
    height: 32.8vw;
    border-radius: 2vw;
    }
  .fac2container h1 {
    border-radius: 1.5vw;
    top: 90%;
    width:13vw;
    height:2.3vw;
    font-size: 1.4vw;
  }
  .fac2container .fac2subtext.displayed2
  {
    border-radius: 1.2vw;
    width:80%;
    height: fit-content;
    margin: 2vw;
    padding: 1.5vw;
    margin-top: 6vw;
    font-size: 1.05vw;
    line-height: 2vw;
  }
  
  .fac3container{
    width: 23vw;
    height: 32.8vw;
    top: 0rem;
    left: 36%;
    border-radius: 2vw;
  } 
  .fac3{
    width: 23vw;
    height: 32.8vw;
    border-radius: 2vw;
    }
  .fac3container h1 {
    border-radius: 1.5vw;
    top: 90%;
    width:16vw;
    height:2.3vw;
    font-size: 1.4vw;
  }  
  .fac3container .fac3subtext.displayed3
  {
    border-radius: 1.2vw;
    width:80%;
    height: fit-content;
    margin: 2vw;
    padding: 1.5vw;
    margin-top: 4vw;
    font-size: 1.05vw;
    line-height: 2vw;
  }
  .fac4container{
    width: 23vw;
    height: 32.8vw;
    top: 0rem;
    left: 54%;
    border-radius: 2vw;
  } 
  .fac4{
    width: 23vw;
    height: 32.8vw;
    border-radius: 2vw;
    }
  .fac4container h1 {
    border-radius: 1.5vw;
    top: 90%;
    width:13vw;
    height:2.3vw;
    font-size: 1.4vw;
  }   
  .fac4container .fac4subtext.displayed4
  {
    border-radius: 1.2vw;
    width:80%;
    height: fit-content;
    margin: 2vw;
    padding: 1.5vw;
    margin-top: 6vw;
    font-size: 1.05vw;
    line-height: 2vw;
    background-color: #252323;
    text-align: center;
  }
  .fac5container{
    width: 23vw;
    height: 32.8vw;
    top: 0rem;
    left: 68%;
    border-radius: 2vw;
  } 
  .fac5{
    width: 23vw;
    height: 32.8vw;
    border-radius: 2vw;
    }
  .fac5container h1 {
    border-radius: 1.5vw;
    top: 90%;
    width:10vw;
    height:2.3vw;
    font-size: 1.4vw;
  }
  .fac5container .fac5subtext.displayed5
  {
    border-radius: 1.2vw;
  width:80%;
  height: 15vw;
  margin: 2vw;
  padding: 1.5vw;
  margin-top: 6vw;
  font-size: 1.05vw;
  line-height: 2vw;
  text-align: center;
  }
}
@media screen and (max-width:767px) 
{
  .pagefivecontainer
  {
    margin-top: 8vw;
    height: 80vh;
    position: relative;
    box-sizing: border-box;
  }
  .facilitycircle{
    border-radius: 40vw;
    width: 27vw;
    height: 32vw;
    top:-5%;
    left:-25%;
    z-index: -2;
  }
  .facilityherotext{
    position: absolute;
    height:4vw;
    top:2vh;
    width: 100vw;
    }
  .facilityherotext h1{
    font-size:5vw;
  }
  .facilitysubcontainer{
    position:absolute;
    width:100vw;
    top: 10vh;
    background-color: gray;
  }
  .facilitysub{
    width:90vw;
    height:fit-content;
  }
  .facilitysubtext {
    color: #9b9b9b;
    position: relative;
    font-family: Morn;
    font-size: 3.2vw;
    font-style: normal;
    line-height: normal;
    }
    .all5images 
    {
      position: relative;
      top: 55%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 80vw;
      height: auto;
    }
    
    .overlay-text
    {
      position: absolute;
      top: 50%; 
      text-align: center;
      color: #fff; 
      font-size: 1.5vw; 
      z-index: 1;
      width: 80vw;
      height: 50vh;
    }
  .facility-image-container-1 {
    position: relative;
    width: 80vw;
    height: 50vh;
    top: 0rem;
    left: 0%;
    border-radius: 2vw;
    z-index: 1;
  }
  .facility-image-1 {

    width: 80vw;
    height: 50vh;
    border-radius: 2vw;
    }
  .facility-text-1{
    border-radius: 1.5vw;
    top: 85%;
    width:55vw;
    padding: 1.4vw 3vw;
    height:9vw;
    left: 50%;
    border-radius: 5vw;
    transform: translateX(-50%);
    font-size: 5.4vw;
    font-family: Morn;
    text-align: center;
    background-color: #252323;
    color: #F5BC25;
    position: absolute;
  }
  .facility-subtext-1 {
    display: none;
  }
  
  .facility-image-container-1 .facility-subtext-1.displayed {
    position: absolute;
    width: 70vw;
    height: fit-content;
    padding: 5vw 3vw;
    font-size: 4vw;
    line-height: 7vw;
    background-color: #252323;
    color: white;
    font-family: Morn;
    text-align: center;
    left: 50%;
    top: 45%;
    border-radius: 4vw;
    transform: translate(-50%,-50%);
    display: block; 
  }
  .facility-image-container-2 {
    position: relative;
    width: 60vw;
    height: 50vh;
    top: 0rem;
    left: 0%;
    border-radius: 2vw;
    z-index: 2;
  }
  .facility-image-2 {

    width: 80vw;
    height: 50vh;
   border-radius: 2vw;
    }
  .facility-text-2{
    border-radius: 1.5vw;
    top: 85%;
    width:55vw;
    padding: 1.4vw 3vw;
    height: 9vw;
    left: 50%;
    border-radius: 5vw;
    transform: translateX(-50%);
    font-size: 5.4vw;
    font-family: Morn;
    text-align: center;
    background-color: #252323;
    color: #F5BC25;
    position: absolute;
  }
  .facility-subtext-2 {
    display: none;
  }
  
  .facility-image-container-2 .facility-subtext-2.displayed {
    position: absolute;
    width: 70vw;
    height: fit-content;
    padding: 5vw 3vw;
    font-size: 4vw;
    line-height: 7vw;
    background-color: #252323;
    color: white;
    font-family: Morn;
    text-align: center;
    left: 50%;
    top: 45%;
    border-radius: 4vw;
    transform: translate(-50%,-50%);
    display: block; 
  }
  .facility-image-container-3 {
    position: relative;
    width: 60vw;
    height: 50vh;
    top: 0rem;
    left: 0%;
    border-radius: 2vw;
    z-index: 3;
  }
  .facility-image-3 {

    width: 80vw;
    height: 50vh;
    border-radius: 2vw;
    }
  .facility-text-3{
    border-radius: 1.5vw;
    top: 85%;
    width:70vw;
    padding: 1.4vw 3vw;
    height:9vw;
    left: 50%;
    border-radius: 5vw;
    transform: translateX(-50%);
    font-size: 5.4vw;
    font-family: Morn;
    text-align: center;
    background-color: #252323;
    color: #F5BC25;
    position: absolute;
  }
  .facility-subtext-3 {
    display: none;
  }
  
  .facility-image-container-3 .facility-subtext-3.displayed {
    position: absolute;
    width: 70vw;
    height: fit-content;
    padding: 5vw 3vw;
    font-size: 4vw;
    line-height: 7vw;
    background-color: #252323;
    color: white;
    font-family: Morn;
    text-align: center;
    left: 50%;
    top: 45%;
    border-radius: 4vw;
    transform: translate(-50%,-50%);
    display: block;
  }
  .facility-image-container-4 {
    position: relative;
    width: 60vw;
    height: 50vh;
    top: 0rem;
    left: 0%;
    border-radius: 2vw;
    z-index: 3;
  }
  .facility-image-4 {

    width: 80vw;
    height: 50vh;
    border-radius: 2vw;
    }
  .facility-text-4{
    border-radius: 1.5vw;
    top: 85%;
    width:55vw;
    padding: 1.4vw 3vw;
    height: 9vw;
    left: 50%;
    border-radius: 5vw;
    transform: translateX(-50%);
    font-size: 5.4vw;
    font-family: Morn;
    text-align: center;
    background-color: #252323;
    color: #F5BC25;
    position: absolute;
  }
  .facility-subtext-4 {
    display: none;
  }
  
  .facility-image-container-4 .facility-subtext-4.displayed {
    position: absolute;
    width: 70vw;
    height: fit-content;
    padding: 5vw 3vw;
    font-size: 4vw;
    line-height: 7vw;
    background-color: #252323;
    color: white;
    font-family: Morn;
    text-align: center;
    left: 50%;
    top: 45%;
    border-radius: 4vw;
    transform: translate(-50%,-50%);
    display: block;
  }
  .facility-image-container-5 {
    position: relative;
    width: 60vw;
    height: 50vh;
    top: 0rem;
    left: 0%;
    border-radius: 2vw;
    z-index: 3;
  }
  .facility-image-5 {

    width: 80vw;
    height: 50vh;
    border-radius: 2vw;
    }
  .facility-text-5{
    border-radius: 1.5vw;
    top: 85%;
    width:55vw;
    padding: 1.5vw 3vw;
    height: 9vw;
    left: 50%;
    border-radius: 5vw;
    transform: translateX(-50%);
    font-size: 5.4vw;
    font-family: Morn;
    text-align: center;
    background-color: #252323;
    color: #F5BC25;
    position: absolute;
  }
  .facility-subtext-5 {
    display: none;
  }
  
  .facility-image-container-5 .facility-subtext-5.displayed {
    position: absolute;
    width: 70vw;
    height: 20vw;
    padding: 6vw 1.6vw;
    font-size: 4vw;
    line-height: 7vw;
    background-color: #252323;
    color: white;
    font-family: Morn;
    text-align: center;
    left: 50%;
    top: 45%;
    border-radius: 4vw;
    transform: translate(-50%,-50%);
    display: block; 
  }
 
}