.Slott
{
    display: flex;
    align-items: center; /* Vertically center content */
    justify-content: center; 
    width: 15rem;
    height: 3.5rem;
    border-radius: 1.5rem;
    background: #D9D9D9;
    color: black;
    font-family: morn;
    font-size: 1.3rem;
    font-style: normal;
    font-weight: 700;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
}
/* @media screen and (min-width: 1024px) and (max-width: 1279px)
{
    .Slot
    {
        width: 11rem;
        font-size: 1rem;
        height: 3rem;
    }
}
@media screen and (min-width: 768px) and (max-width: 1023px)
{
    .Slot
    {
        width: 8rem;
        font-size: 0.8rem;
        height: 2.2rem;
    }
}
@media screen and (min-width: 5120px)
{
    .Slot
    {
        width: 45rem;
        font-size: 4rem;
        height: 8rem;
    }
} */
@media screen and (min-width:0px) 
{
    .Slott
    {
        width: 25vw;
        font-size: 2.2vw;
        height: 6vw;
        border-radius: 1.6vw;
        border-width: 0.2vw;
        margin:0;
        color: black;

    }
}
@media screen and (min-width:1024px)
{
    .Slott
    {
        width: 18vw;
        font-size: 1.7vw;
        height: 4vw;
        border-radius: 1.3vw;
        margin: 0;
        margin-top: 0.5vw;
        color: black;

    }    
}