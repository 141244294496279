
.PricingCardContainer
{
    position: relative;
    width: 20rem;
    height: 11.5rem;
    border-radius: 0.9rem;
    background-color: #2F2E2C;
}
#price-circle
{
    position: absolute;
    top: -3rem;
    left: 13.5rem;
}
.price-type-h1
{
    position: absolute;
    font-family: Maven;
    left: 1rem;
    color: white;
    top: -0.7rem;
    font-size: 1.7rem;
}
.price-type-h2
{
    position: absolute;
    font-family: Maven;
    left: 1rem;
    color: white;
    top: -0.7rem;
    font-size: 1.7rem;
}
#bottom-right-card
{
    position: absolute;
    top: -2.2rem;
    left: -0.05rem;
    border-radius: 0.9rem;
}
#rupee
{
    position: absolute;
    color: #FFF;
    text-align: center;
    font-family: Mate;
    font-size: 1.75rem;
    font-style: normal;
    font-weight: 400;
    font-family: Mate;
    top: 3rem;
    left: 1rem;
}
.amount
{
    color: #F5BC25;
    text-align: center;
    font-family: Maven;
    font-size: 3rem;
    font-style: normal;
    font-weight: 600;
    position: absolute;
    left: 2rem;
    top: 1rem;
}
.amount2
{
    color: #F5BC25;
    text-align: center;
    font-family: Maven;
    font-size: 3rem;
    font-style: normal;
    font-weight: 600;
    position: absolute;
    left: 2rem;
    top: 1rem;
}
.amount span
{
    color: #FFF;
}
.Features
{
    position: absolute;
    color: white;
    font-family: Maven;
    left: 1rem;
    top: 7rem;
}
.Features h2
{
    color: #F5BC25;
    font-family: Maven;
    font-size: 1.1rem;
    font-style: normal;
    font-weight: 600;
    line-height: 50%; 
}
#white-color
{
    color: white;
}
@media screen and (min-width:0px) and (max-width:767px) 
{
    .PricingCardContainer
    {
        width: 40vw;
        height: 25vw;
        border-radius: 2vw;
    }
    #price-circle
    {
        position: absolute;
        top: -25%;
        left: 80%;
        width: 15vw;
        height: 15vw;
    }
    .price-type-h1
    {
        left: 1.5vw;
        top: 3%;
        font-size: 4vw;
    }
    #bottom-right-card
    {
        position: absolute;
        top: 20%;
        left: 30%;
        border-radius: 2vw;
        width: 28vw;
        height: 20vw;
    }
    #rupee
    {
        font-size: 5.5vw;
        top: 30%;
        left: 1vw;
    }
    .amount
    {
        font-size: 6vw;
        left: 5vw;
        top: 30%;
    }
    .Features
    {
        left: 1vw;
        top: 70%;
    }
    #white-color
    {
        margin-top: 1.5vw;
    }
    .Features h2
    {
        font-size: 2.5vw; 
    }
}
/* @media screen and (min-width:768px) and (max-width:1278px) 
{
    .PricingCardContainer
    {
        width: 18vw;
        height: 11.9vw;
        border-radius: 0.9vw;
    }
    #price-circle
    {
        position: absolute;
        top: -35%;
        left: 60%;
        width: 8vw;
        height: 8vw;
    }
    .price-type-h1
    {
        left: 1vw;
        top: 2%;
        font-size: 1.5vw;
    }
    #bottom-right-card
    {
        position: absolute;
        top: 7%;
        left: 22%;
        border-radius: 0.9vw;
        width: 14vw;
        height: 11vw;
    }
    #rupee
    {
        font-size: 1.7vw;
        top: 27%;
        left: 1vw;
    }
    .amount
    {
        font-size: 2.8vw;
        left: 2.5vw;
        top: 18%;
    }
    .Features
    {
        left: 1vw;
        top: 63%;
    }
    #white-color
    {
        margin-top: 0.8vw;
    }
    .Features h2
    {
        font-size: 1.1vw; 
        line-height: 1vw;
    }
} */
@media screen and (min-width:768px) 
{
    .PricingCardContainer
    {
        width: 18vw;
        height: 11.9vw;
        border-radius: 0.9vw;
    }
    #price-circle
    {
        position: absolute;
        top: -20%;
        left: 70%;
        width: 8vw;
        height: 8vw;
    }
    .price-type-h1
    {
        left: 1vw;
        top: 2%;
        font-size: 1.5vw;
    }
    .price-type-h2
    {
        left: 1vw;
        top: 2%;
        font-size: 1.5vw;
    }
    #bottom-right-card
    {
        position: absolute;
        top: 7%;
        left: 22%;
        border-radius: 0.9vw;
        width: 14vw;
        height: 11vw;
    }
    #rupee
    {
        font-size: 1.7vw;
        top: 35%;
        left: 1vw;
    }
    .amount
    {
        font-size: 2.8vw;
        left: 2.5vw;
        top: 25%;
    }
    .amount2
    {
        font-size: 4vw;
        left: 1vw;
        top: 20%;
    }
    .Features
    {
        left: 1vw;
        top: 63%;
    }
    #white-color
    {
        margin-top: 0.8vw;
    }
    .Features h2
    {
        font-size: 1.1vw; 
        line-height: 0.8vw;
    }
}