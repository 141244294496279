
.User-title
{
    position: absolute;
    font-family: Morn;
    font-size: 4.2vw;   
    top: 2vw;
    left: 20vw;
    color: white;
}
.User-line
{
    position: absolute;
    left: 20vw;
    top: 7.5vw;
    background-color: #F5BC25;
    height: 0.2vw;
    width: 75vw;
}

.adding-user-rectangle {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 50%;
    left: 45%;
    transform: translate(-50%,-50%);
    width: 50vw;
    height: 30vw;
    /* backdrop-filter: blur(5px); Apply a blur effect */
}

.adding-user-rectangle::before {
    content: "";
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #21201E; /* Your desired background color */
    opacity: 0.9;
}

#username
{
    position: absolute;
    top:0;
    left: 0;
    font-size: 3vw;
    color: white;
    z-index: 2;
    font-family: Morn;
}
#enter-name
{
    position: absolute;
    z-index: 2;
    top:4vw;
    border: 2px solid #F5BC25;
    /* height: 2vw; */
    width: 30vw;
    padding-left: 0.5rem;
    color: white;
    font-size: 2vw;
    letter-spacing: 1px;
    font-family: Morn;
    background-color: transparent;
    /* backdrop-filter: blur(5px); */
    opacity: 0.8;
    left: 0;
    transition: 0.1s all ease-in-out;
}

#enter-name:hover
{
    backdrop-filter: blur(10px);
}
#password
{
    position: absolute;
    top:9vw;
    left: 0;
    font-size: 3vw;
    color: white;
    z-index: 2;
    font-family: Morn;
}
#enter-email
{
    position: absolute;
    z-index: 2;
    top:13vw;
    border: 2px solid #F5BC25;
    width: 30vw;
    padding-left: 0.5vw;
    color: white;
    font-size: 2vw;
    letter-spacing: 1px;
    font-family: Morn;
    background-color: transparent;
    backdrop-filter: blur(5px);
    opacity: 0.8;
    left: 0;
}
#enter-email:hover
{
    backdrop-filter: blur(5px);
}
#enter-username-label
{
    position: absolute;
    top:18vw;
    left: 0;
    font-size: 3vw;
    color: white;
    z-index: 2;
    font-family: Morn;
}
#enter-username
{
    position: absolute;
    z-index: 2;
    top:22vw;
    border: 2px solid #F5BC25;
    /* height: 2rem; */
    width:30vw;
    padding-left: 0.5vw;
    color: white;
    font-family: Morn;
    font-size: 2vw;
    letter-spacing: 1px;
    background-color: transparent;
    backdrop-filter: blur(5px);
    opacity: 0.8;
    left: 0;
}
#enter-username:hover
{
    backdrop-filter: blur(5px);
}

.login-user-container {
    position: absolute;
    top: 92%;
    left: 0;
    width: 10vw;
    height: 4vw;
    font-size: 2vw;
    background-color: transparent;
    border: 2px solid #F5BC25;
    border-radius: 1vw;
    color: white;
    overflow: hidden; /* Hide overflow for pseudo-element */
    cursor: pointer; /* Add pointer cursor for interaction */
    transition: 0.2s all ease-in-out;
}
.login-user-container:hover
{
    background-color: #F5BC25;
    color: black;
}

.Removing-user-rectangle {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 12.2vw;
    left: 75%;
    transform: translateX(-50%);
    width: 28vw;
    height: 30vw;
    /* backdrop-filter: blur(5px); Apply a blur effect */
}
.Removing-user-rectangle select
{
    left: 0;
    top:0;
    font-size: 2vw;
    width: 30vw;
    height: 2.8vw;
}
.login-user-container {
    position: absolute;
    top: 92%;
    left: 0;
    width: 10vw;
    height: 4vw;
    font-size: 2vw;
    background-color: transparent;
    border: 2px solid #F5BC25;
    border-radius: 1vw;
    color: white;
    overflow: hidden; /* Hide overflow for pseudo-element */
    cursor: pointer; /* Add pointer cursor for interaction */
    transition: 0.2s all ease-in-out;
}
.Remove-user-button
{
    position: absolute;
    top: 15%;
    left: 50%;
    transform: translateX(-50%);
    width: 10vw;
    height: 4vw;
    font-size: 1.5vw;
    background-color: transparent;
    border: 2px solid #F5BC25;
    border-radius: 1vw;
    color: white;
    overflow: hidden; /* Hide overflow for pseudo-element */
    cursor: pointer; /* Add pointer cursor for interaction */
    transition: 0.2s all ease-in-out;
}
.Remove-user-button:hover
{
    background-color: #F5BC25;
    color: black;
}