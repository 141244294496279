.slick-dots li button {
    width: 0.001vw;
    height: 0.001vw;
    border-radius: 1vw;
    background-color:  #F5BC25;
  }
.slick-dots li:not(.slick-active) button {
    background-color:  #CCCCCC; 
    width: 0.001vw;
    height: 0.001vw;
}
  
  /* Customize the active dot color */
  .slick-dots li.slick-active button {
    background-color:  #F5BC25;
    width: 0.001vw;
    height: 0.001vw;
  }

.slick-prev,
.slick-next {

  width: 0.3vw;
  height: 3vw;
  border-radius: 3vw;
}

.slick-prev {
  left: 0vw; 
  z-index: 3;
}

.slick-next {
  right: 2vw; 
}
