.audio {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100vw;
  height: 100vh;
}


.audio .text-wrapper {
  position: absolute;
  color: white;
  font-family: Morn;
  font-size: 4.2vw;
  top: 2.2vw;
  left: 20vw;
}

.audio .text-wrapper-1 {
  position: absolute;
  color: white;
  font-family: Morn;
  font-size: 2.8vw;
  top: 10vw;
  left: 20vw;
}

.media-line {
  position: absolute;
  left: 20vw;
  top: 8vw;
  background-color: #F5BC25;
  height: 0.3vw;
  width: 75vw;
}

.audio .text-wrapper-2 {
  position: absolute;
  color: white;
  font-family: Morn;
  font-size: 2.8vw;
  top: 28vw;
  left: 20vw;
}

.audio .text-wrapper-10 {
  border: 0.2vw solid #F5BC25;
  background-color: transparent;
  color: #ffffff;
  font-family: Morn;
  font-size: 2vw;
  font-weight: 400;
  left: 20vw;
  padding: 0.5vw 0.1vw;
  letter-spacing: 0.1vw;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 14vw;
  white-space: nowrap;
  width: 42vw;
}

.confirm-video-1 {
  width: 8vw;
  height: 3.7vw;
  border: 0.2vw solid #F5BC25;
  background-color: transparent;
  font-family: Morn;
  color: white;
  font-size: 1.5vw;
  position: absolute;
  top: 14vw;
  left: 65vw;
  transition: 0.2s all ease-in-out;
  cursor: pointer;
}

.confirm-video-1:hover {
  background-color: #F5BC25;
  color: black;
  font-weight: 700;
}

.confirm-video-2 {
  width: 8vw;
  height: 3.7vw;
  border: 0.2vw solid #F5BC25;
  background-color: transparent;
  font-family: Morn;
  color: white;
  font-size: 1.5vw;
  position: absolute;
  top: 21vw;
  left: 65vw;
  transition: 0.2s all ease-in-out;
  cursor: pointer;
}

.confirm-video-2:hover {
  background-color: #F5BC25;
  color: black;
  font-weight: 700;
}

.confirm-video-3 {
  width: 8vw;
  height: 3.7vw;
  border: 2px solid #F5BC25;
  background-color: transparent;
  font-family: Morn;
  color: white;
  font-size: 1.5vw;
  position: absolute;
  top: 33vw;
  left: 82vw;
  transition: 0.2s all ease-in-out;
  cursor: pointer;
}

.confirm-video-3:hover {
  background-color: #F5BC25;
  color: black;
  font-weight: 700;
}

.audio .text-wrapper-11 {
  border: 3px solid #F5BC25;
  background-color: transparent;
  color: #ffffff;
  font-family: Morn;
  font-size: 2vw;
  font-weight: 400;
  left: 20vw;
  padding: 0.5vw 1vw;
  letter-spacing: 0.64px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 21vw;
  white-space: nowrap;
  width: 42vw;
}

.audio .text-wrapper-12 {
  border: 0.2vw solid #F5BC25;
  background-color: transparent;
  color: #ffffff;
  font-family: Morn;
  font-size: 2vw;
  font-weight: 400;
  left: 20vw;
  padding: 0.5vw 1vw;
  letter-spacing: 0.64px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 33vw;
  white-space: nowrap;
  width: 22vw;
}

.audio .text-wrapper-13 {
  border: 0.2vw solid #F5BC25;
  background-color: transparent;
  color: #ffffff;
  font-family: Morn;
  font-size: 2vw;
  font-weight: 400;
  left: 44vw;
  padding: 0.5vw 1vw;
  letter-spacing: 0.64px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 33vw;
  white-space: nowrap;
  width: 35vw;
}
